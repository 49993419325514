import React from 'react';
import ReactDOM from "react-dom";
import { RouteConfig } from '@/constant/config';
import './index.less';
class RouteStatus extends React.Component {
  static show = params => {
    let container = document.createElement("div");
    container.setAttribute('name', 'dialog');
    let main = document.getElementById('main');
    main && main.appendChild(container);
    function closeHandle() {
      ReactDOM.unmountComponentAtNode(container);
      main && main.removeChild(container);
      container = null;
    }
    ReactDOM.render(
      <RouteStatus {...params} Hide={closeHandle} />,
      container
    );
    return RouteStatus
  };
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    const { type, Hide } = this.props;
    if(type == 'success' || type == 'fail'){
        setTimeout(()=>{
          Hide()
        }, 2000);
    }
  }
  render() {
    const { type } = this.props;
    return (
        <div className={`route-tips ${RouteConfig[type].status}`}>
            <span className={`spinner ${RouteConfig[type].spin}`}></span>
            {RouteConfig[type].text}
        </div>
    )
  }
}

  export default RouteStatus;