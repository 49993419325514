import { memo, useState, useEffect, useRef} from 'react';
import { useDispatch } from 'react-redux'
import { continueWork } from '@/utils/api'
import { HOT_AREA_TYPE } from '@/store/action/type';
import Style from './index.module.less';
import { orderClearReset } from "@/utils/DataUtil";

let timer = null;
const GoOnWork = ({TRIP_INFO}) => {
    const [min, setMin] = useState(30);
    const dispatch = useDispatch();
    const goBtnRef = useRef(null);
    useEffect(() => {
        timer = setInterval(() => {
          setMin(min - 1);
        }, 1000);
        if(min==0){
            next()
            clearInterval(timer);
        }
        return () => {
          clearInterval(timer);
        };
    }, [min]);
    const next = async () => {
        let res = null;
        try {
          res = await continueWork({
            trip_id: TRIP_INFO?.trip_id
          });
          clearInterval(timer);
          if(res.code === 0){
            // try {
            //   // 通知robonode听单中
            //   await SendToRoboFn(SendToRoboMap.LISTEN_ORDER)
            // } catch (err){}
          }
        } finally {
          orderClearReset(dispatch);
        }
    };

    useEffect(()=>{
      const goBtnArea = goBtnRef.current.getBoundingClientRect();
      const GoBtnAreaInfo = `${goBtnArea.x}, ${goBtnArea.y}, ${goBtnArea.width}, ${goBtnArea.height}`;
      dispatch({
        type: HOT_AREA_TYPE.GoOn,
        data: GoBtnAreaInfo
      });
    })

    return (
        <div ref={goBtnRef} className={Style.Container} onClick={()=> next()}>
            <span className={Style.Dymic}>
            { min != 0 ?<i className={Style.Num}>{min}</i>: ''}{min != 0 ? 's ' :''}
              继续接单
            </span>
        </div>
    )
}

export default memo(GoOnWork);