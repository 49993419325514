import { Toast } from 'antd-mobile';

class Socket {
  constructor(url, {}) {
    this.url = url;
    this.socket = null;
    this.connectCbs = [];
    this.messageCbs = [];
    this.init();
    this.timer = 0; 
  }
  onConnect(fn) {
    this.listen('connectCbs', fn);
  }
  offConnect(fn) {
    return this.off('connectCbs', fn);
  }
  onMessage(fn) {
    this.listen('messageCbs', fn);
  }
  offMessage(fn) {
    return this.off('messageCbs', fn);
  }
  listen(stackName, fn) {
    this[stackName].push(fn);
  }
  off(stackName, fn) {
    let index = this[stackName].indexOf(fn);
    if (index === -1) return false;
    this[stackName].splice(index, 1);
    return true;
  }
  init() {
    console.log('$$init', this.url);
    this.socket = new WebSocket(this.url);
    this.socket.addEventListener('open', () => {
      clearTimeout(this.timer);
      this.connectCbs.forEach((cb) => {
        if (typeof cb !== 'function') return;
        cb();
      });
    });
    this.socket.addEventListener('message', (event) => {
      clearTimeout(this.timer);
      this.messageCbs.forEach((cb) => {
        if (typeof cb !== 'function') return;
        cb(JSON.parse(event.data));
      });
      // console.log('message', event, JSON.parse(event.data));
    });
    this.socket.addEventListener('error', (event) => {
      console.log('socket error', event);
      clearTimeout(this.timer);
      this.timer = setTimeout(()=>{
        this.reconnect();
      }, 5000);
    });
    this.socket.addEventListener('close', (event) => {
      // console.log('close', event);
    });
  }
  reconnect() {
    Toast.show({
      content: 'robonode重连中...',
      duration: 2000,
    });
    console.log('$$reconnect');
    this.socket.close();
    this.socket = null;
    this.init();
  }
  send(data) {
    // console.log('$$send', data);
    this.socket.send(JSON.stringify(data));
  }
  close() {
    this.connectCbs = [];
    this.messageCbs = [];
    this.socket.close();
  }
}

export default Socket;