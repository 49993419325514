import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { AppFn } from '@/utils/JsBrigde.js';
import { 
  CHANGE_ORDER_STATUS,
} from '@/store/action/type';
import './App.css';
import { OrderStatus } from '@/constant/order';
import { NotifyAppLocation } from "./utils/index";
const { LISTEN_ORDER } = OrderStatus;

function isEmptyObj(object){
  if (Object.keys(object).length!= 0) {
    return false;
  }
  return true;
}
function App() {
  const NavArea = useSelector(state => state.hotArea.NavArea);
  const ReloadArea = useSelector(state => state.hotArea.ReloadArea);
  const CardArea = useSelector(state => state.hotArea.CardArea);
  const GoOnArea = useSelector(state => state.hotArea.GoOnArea);
  const TodayOrder = useSelector(state => state.hotArea.TodayOrder);
  const OrderList = useSelector(state => state.hotArea.OrderList);
  const IsHotAll = useSelector(state => state.hotArea.IsHotAll);
  const { locateInfo } = useSelector((state) => state.map);
  const showMivzRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if(showMivzRef.current) return;
    AppFn.ShowMivz();
    dispatch({
      type: CHANGE_ORDER_STATUS,
      data: LISTEN_ORDER,
    });
    showMivzRef.current = true
    AppFn.setPlate();
  });
  useEffect(() => {
    AppFn.SetHotArea(IsHotAll, [NavArea, ReloadArea, CardArea, GoOnArea, TodayOrder, OrderList]);
  }, [NavArea, ReloadArea, CardArea, GoOnArea, TodayOrder,OrderList, IsHotAll]);

  useEffect(() => {
    if(isEmptyObj(locateInfo)) return;
    let locateData = {
      ...locateInfo,
      time: locateInfo?.timestamp
    };
    // let data = {
    //   lon: 120.64007, 
    //   lat: 31.419376, 
    //   alt: 0.000000,
    //   heading: 166.317700,
    //   speed: 7.419325,
    //   time: 1638495889533.000000
    // }
    // setInterval(() => {
    // }, 1000);
    NotifyAppLocation(locateData);
  }, [locateInfo]);
  return (
    <div className="wrapper">
      <div className="main" id='main'>
        <Outlet />
      </div>
    </div>
  );
}
export default App;
