import { Toast } from 'antd-mobile';
import axios from 'axios';
import { HOST } from '../constant/config';
import { genTraceId } from '@/utils/index';
import store from '@/store';
import { AppFn } from '@/utils/JsBrigde';
import logSend from '@/utils/aLog';
import {
  CHANGE_ORDER_STATUS,
} from '@/store/action/type';
import { OrderStatus } from '@/constant/order';
import { FeiShuUrl } from "@/constant/config";
const { IDEL } = OrderStatus;

// 过滤所有空字符串参数
const falsyFilter = (params) => {
  const filtedParams = {};
  Object.keys(params)
    .filter((field) => !!params[field])
    .forEach((field) => {
      filtedParams[field] = params[field];
    });
  return filtedParams;
};

const instance = axios.create({
  baseURL: '/',
  timeout: 10000,
});
instance.interceptors.request.use((conf) => {
  const mapState = store.getState()?.map;
  let { params } = conf;
  if (conf.method === 'get' && params) {
    // 过滤所有空字符串参数
    params = falsyFilter(conf.params);
  }
  conf.headers['Driver-Token'] = localStorage['driver_token'] || '';
  // // 本地测试使用
  // const locateInfo = {
  //   lon: 120.64007, 
  //   lat: 31.419376, 
  //   heading: 0
  // };
  // conf.headers['Driver-GPS'] = JSON.stringify(locateInfo);
  conf.headers['Driver-GPS'] = JSON.stringify(mapState?.locateInfo);
  conf.headers['Grpc-Metadata-traceid'] = genTraceId()
  return {
    ...conf,
    params,
  };
});

instance.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    Toast.show(response.statusText);
    return Promise.reject(new Error(response.statusText));
  },
  (error) => {
    const { response = {} } = error;
    logSend({
      action: 'response error',
      data: error
    });  
    let message = response.data?.message
    message && Toast.show(response.data?.message || '请求失败');
    // token失效
    if (response.status === 401) {
     try{
      store.dispatch({
        type: CHANGE_ORDER_STATUS,
        data: IDEL
      });
      AppFn.HideMviz();
     } catch(err){
       console.log('err', err);
     }
      localStorage?.removeItem("driver_token")
      window.location.replace('/#/login');
      return Promise.reject(response.data);
    }
    return Promise.reject(response.data);
  }
);

export const get = (url, params) => instance.get(HOST + url, { params });

export const logGet = (url, params) => instance.get(url, { params });

export const post = (url, params) => instance.post(HOST + url, params);

export const fsPush = (params) => instance.post(FeiShuUrl, {
  "msg_type":"text",
  "content": {"text": JSON.stringify(params)}
});




