import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import order from './reducers/order';
import robonode from './reducers/robonode';
import map from './reducers/map';
import slider from './reducers/slider';
import hotArea from './reducers/hotArea';
import check from './reducers/check';
import car from './reducers/car';
import etd from "./reducers/etd";
export default createStore(
  combineReducers({
    order,
    robonode,
    map,
    slider,
    hotArea,
    check,
    car,
    etd
  }),
  composeWithDevTools()
);
