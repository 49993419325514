import React, {useRef, useEffect} from 'react';
import style from './index.module.less';
import logo from './img/logo.png';
import { useDispatch } from 'react-redux';
import { HOT_AREA_TYPE } from '@/store/action/type';
import { getAreaInfo } from '@/utils/index';
import SliderList from './sliderList';
import GoWork from './goWork';
// import Reload from './roload';

export default function Slider() {
  const dispatch = useDispatch();
  const hotNav = useRef(null);
  const notifyNavHotArea= () => {
    dispatch({
      type: HOT_AREA_TYPE.Nav, 
      data: getAreaInfo(hotNav)
    });
  }
  useEffect(() => {
    notifyNavHotArea();
  });
  return (
    <div ref={hotNav} className={style.container}>
      <div className={style.IconWrap}>
        <img className={style.Icon} src={logo}></img>
      </div>
      <SliderList/>
      <GoWork/>
      {/* <Reload/> */}
    </div>
  );
}
