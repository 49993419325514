
import { useState, useEffect, useRef } from "react"
import {useSelector, useDispatch} from "react-redux"
import { AppFn } from '@/utils/JsBrigde';
import { orderList } from '@/utils/api';
import { HOT_AREA_TYPE } from '@/store/action/type';
import { OrderStatus } from '@/constant/order';
import OrderIcon from "./orderIcon.png";
import "./index.less";
let { MTOUR_ACCEPT } = OrderStatus;

export default function MtourComingTips(TRIP_INFO) {
    const OS = useSelector((state) => state.order.OrderStatus);
    const mtourId = useSelector(state => state?.order?.RESERVE_TRIP?.trip_id);
    const trip_id = useSelector((state) => state?.order?.TRIP_INFO?.trip_id);
    const car_time = useSelector((state) => state?.order?.TRIP_INFO?.car_time);
    const [showComing, setShowComing] = useState(false);
    const [showTodayCount, setShowTodayCount] = useState(false);
    const [showStart, setShowStart] = useState(false);

    const [today, setToday] = useState(0);
    const dispatch = useDispatch();

    const comingTimer = useRef();
    const remainTimer = useRef();

    const getHourMin = (str) => { 
        let arr = str?.split(" ")[1]?.split(":");
        return `${arr[0]}:${arr[1]}`
    }
    const todayOrder = useRef(null);

    const mtourComing = () => {
        clearInterval(comingTimer.current);
        setShowComing(false);
        AppFn.playLocalAudio("mtour_order_coming.mp3");
        setShowComing(true)
        comingTimer.current = setTimeout(()=>{
            setShowComing(false)
        }, 3000);
    }
    useEffect(() => {
        let mtourData = localStorage.getItem("mtourData")||"{}";
        mtourData = JSON.parse(mtourData);
        if(parseInt(mtourId) && !mtourData[mtourId]){
            mtourData[mtourId] = true;
            mtourComing();
            localStorage.setItem("mtourData", JSON.stringify(mtourData));
        }
    }, [mtourId, trip_id]);

    const showToday = async () => {
        let res = await orderList({
            today_trip: true
        });
        if(res.code === 0){
            let num = res?.data?.page_pager?.total || 0
            if(num > 0) {
                setToday(num);
                setShowTodayCount(true);
                AppFn.playLocalAudio("today_has_mtour.mp3");
                setTimeout(()=>{
                    setShowTodayCount(false);
                }, 5000);
            }
        }
    }
    useEffect(async () => {
        await showToday();
    }, []);

    const setComingStore = (trip_id) => {
        let mtourComingPlay = localStorage.getItem("mtourComingPlay") || "{}";
        mtourComingPlay = JSON.parse(mtourComingPlay);
        if(mtourComingPlay[trip_id]) return;
        mtourComingPlay[trip_id] = {
            oneHour: false,
            halfHour: false
        };
        localStorage.setItem("mtourComingPlay", JSON.stringify(mtourComingPlay));
    }
    const handleComingPlay = (trip_id, type) => {
        let mtourComingPlay = localStorage.getItem("mtourComingPlay")||"{}";
        mtourComingPlay = JSON.parse(mtourComingPlay);
        if(type == "one") {
            if(!mtourComingPlay[trip_id].oneHour){
                mtourComingPlay[trip_id].oneHour = true;
                AppFn.playLocalAudio("one_hour_start.mp3");
                setShowStart(true);
                setTimeout(()=>{
                    setShowStart(false);
                }, 3000);
            }
        }
        if(type == "half") {
            if(!mtourComingPlay[trip_id].halfHour){
                mtourComingPlay[trip_id].halfHour = true;
                AppFn.playLocalAudio("before_mtour_30_minutes.mp3");
                setShowStart(true);
                setTimeout(() => {
                    setShowStart(false);
                }, 3000);
            }
        }
        localStorage.setItem("mtourComingPlay", JSON.stringify(mtourComingPlay));
    }

    useEffect(() => {
        if(OS !== MTOUR_ACCEPT) return clearInterval(remainTimer?.current);
        let now = TRIP_INFO?.TRIP_INFO?.trip_now_time;
        let use = TRIP_INFO?.TRIP_INFO?.car_time;
        if(!now || !use) return;

        function getMiniSecond(time) {
            return (new Date(time)).getTime();
        }
        function getSumTime(n, u) {
            n =  getMiniSecond(n);
            u =  getMiniSecond(u);
            let gap = u - n;
            if(gap<0) return 0;
            return gap/1000;
        }
        let remainTime = getSumTime(now, use);
        clearInterval(remainTimer?.current);
        setComingStore(trip_id);
        remainTimer.current = setInterval(() => {
            remainTime--;
            if(3600 - remainTime <= 5*60 && 3600-remainTime >0) {
                handleComingPlay(trip_id, "one");
            }
            if(1800 - remainTime <= 5*60 && 1800-remainTime >= 0) {
                handleComingPlay(trip_id, "half");
            }
            if(remainTime<=0) {
                clearInterval(remainTimer?.current);
            }
        }, 1000);
    }, [trip_id, OS]);

    const showOrderList = ()=>{
        dispatch({
            type: 'CHANGE_TAB',
            data: "order",
        });
        setShowTodayCount(false);
    }

    useEffect(()=>{
        const TodayOrder = todayOrder?.current?.getBoundingClientRect();
        const TodayOrderInfo = `${TodayOrder?.x}, ${TodayOrder?.y}, ${TodayOrder?.width}, ${TodayOrder?.height}`;
        dispatch({
            type: HOT_AREA_TYPE.TodayOrder,
            data: TodayOrderInfo
        });
        if(!showTodayCount){
            dispatch({
                type: HOT_AREA_TYPE.TodayOrder,
                data: []
            });
        }
    },[showTodayCount])

    return (<>
        { showComing ? <div className="mtour-coming-tips">
                <div className="tips-wrapper">
                    <img className="order-icon" src={OrderIcon} alt="订单图标" />
                    <span>Mtour 来单了！</span>
                </div>
            </div> : ""
        }

        { showTodayCount ? <div ref={todayOrder} className="today-order-count">
                <div className="today-count-wrapper">
                    <p>今日有{today}单 Mtour订单预约单</p>
                    <span onClick={()=> showOrderList()}><i>查看</i></span>
                </div>
            </div> : ""
        }

        { showStart ? <div className="order-start-tips">
                <div className="start-tips-wrapper">
                    <span>{getHourMin(car_time)}有Mtour用车，</span>
                    <span>请尽快前往</span>
                </div>
            </div> : ""
        }
    </>)
}