import { Toast } from 'antd-mobile';
import SlsWebLogger from 'js-sls-logger';
const { REACT_APP_ENV } = process.env;
const project = 'k8s-log-ce4d63b68ce08473bbb4e2392f22c6aea';
const betaProject = 'k8s-log-ce77fee6584cd46b9a1e8a98e5c305e79';
const devLogStore = 'robotaxi-web-driver';
const betaDeployLogStore = 'robotaxi-web-driver-beta';
const isProd = REACT_APP_ENV === 'prod';
const isBeta = REACT_APP_ENV === 'beta';
const isDev = REACT_APP_ENV === 'dev' || 'development';
let baseOpts = null;

if(isDev){
    baseOpts = {
        project: project,              
        logstore: devLogStore
    };
}
if(isBeta){
    baseOpts = {
        project: betaProject,                 
        logstore: betaDeployLogStore
    };
}
if(isProd){
    baseOpts = {
        project: betaProject,                 
        logstore: devLogStore
    };
};
const opts = {
    host: 'cn-beijing.log.aliyuncs.com', 
    ...baseOpts,   
    time: 3, 
    count: 10,
};
const logger = new SlsWebLogger(opts);
if(!baseOpts){
    Toast.show({
        content: 'log配置出错'
    });
}
const logSend = (param) => {
    try{
        logger.send({
            timestamp: (new Date()).getTime(),
            phone: localStorage['robotaxi_phone'],
            carPlate: localStorage['robotaxi_carPlate'],
            ...param,
        });
    } catch(err){
        console.log(err);
    }
    
};
export default logSend;