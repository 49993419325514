import { memo, useState, useMemo, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login, getCarList, eventInfoSync, getCarNum } from '@/utils/api';
import { Toast} from 'antd-mobile';
import { AppFn } from '@/utils/JsBrigde';
import { 
  MAP_TYPE,
  saveTripInfo, //  更新store行程信息action
} from '@/store/action/type';
import logSend from '@/utils/aLog';
import { queryURLParameter } from "@/utils/index";
import { REACT_APP_ENV } from "@/constant/config";
import tipsIcon from "./tipsIcon.png";
import './index.less';

function getVersionText(env) {
  switch(env){
    case "beta":
      return "_灰度环境";
    case "dev":
      return "_测试环境";  
    case "development":
      return "_本地环境";
    default:
      return "";
  }
}

const Login = () => {
  // "RX5-EFB6808"
  const boxid = useSelector((state) => state?.map?.locateInfo?.boxid);
  const roboStatus = useSelector(state => state.robonode.ready);
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [carPlate, setCarPlate] = useState('');
  const [tipText, setTipText] = useState('');
  const [actionFlag, setActionFlag] = useState(false);
  const [columns, setcolumns] = useState([]);
  const [isShowLogin, setShowLogin] = useState(false);
  const [plateLicense, setPlateLicense] = useState('');
  const [isTimeout, setIsTimeout] = useState(false);
  const dispatch = useDispatch();
  const phoneRef = useRef();
  const timeoutRef = useRef();

  const disabled = useMemo(() => {
    return !(phone && plateLicense);
  }, [phone, plateLicense]);

  const queryCarNum = async (deviceBoxId) => {
    if(!deviceBoxId) {
      return;
    };
    let res = await getCarNum({
      deviceBoxId
    });
    if(res.code == 0){
      if(res.data.plate_license){
        setPlateLicense(res.data.plate_license);
      }
    } else {
      Toast.show('车牌获取失败');
    }
  }

  const timeout = () => {
    let num = 0;
    setIsTimeout(false);
    clearInterval(timeoutRef.current);
    timeoutRef.current = setInterval(()=>{
      num++;
      if(num>6){
        setIsTimeout(true);
        clearInterval(timeoutRef.current);
      }
    }, 1000);
  }

  const retry = async () => {
    timeout();
    await queryCarNum(boxid);
  }
  useEffect(() => {
    queryCarNum(boxid);
  }, [boxid]);

  useEffect(async () => {
    if(localStorage['driver_token']){
      let checkLoginRes = null;
      try{
        checkLoginRes = await eventInfoSync();
        if(checkLoginRes.code==0){
          navigate('/home');
        }else{
          localStorage?.removeItem("driver_token")
          setShowLogin(true);
        }
      } finally {
        setShowLogin(true);
      }
    }else {
      setShowLogin(true);
    }
    getCarList().then(({ code, data }) => {
      if (code !== 0) return;
      setcolumns([data.car_list]);
    });
  }, []);

  function handleLogin() {
    if (disabled) return;
    logSend({
      action: 'loginClick',
      phone,
      plateLicense
    });
    login({ phone, car: plateLicense })
      .then((res) => {
        if (res.code === 500) {
          logSend({
            action: 'loginException',
            phone,
            plateLicense
          });
          setTipText(res.message);
          return;
        }
        if (res.code) {
          logSend({
            action: 'loginFail',
            phone,
            carPlate
          });
          Toast.show(res.message || '请求失败');
          return;
        }
        localStorage['robotaxi_phone'] = phone;
        localStorage['robotaxi_carPlate'] = plateLicense;
        localStorage['momenta_carplate'] = plateLicense;
        localStorage['driver_token'] = res.data.driver_token;
        localStorage['momenta_source'] = queryURLParameter('source');
        // 接单重置robonode路径规划
        dispatch({
          type: MAP_TYPE.route,
          data: {}
        })
        dispatch(saveTripInfo({}));
        logSend({
          action: 'loginSucess',
          phone,
          carPlate
        });
        navigate('/home');
      })
  }
  function handleChange(val) {
    setCarPlate(val[0]);
  }

  useEffect(() => {
    try{
      AppFn.SetHotArea(true, []);
      AppFn.HideMviz();
    } catch(err){
      console.log('err', err);
    }
    timeout();
  }, []);

  return (
    <>
      { isShowLogin ? 
        <div className="login-container">
          {/* <Popup
            visible={actionFlag}
            onMaskClick={() => {
              setActionFlag(false);
            }}
          >
            <PickerView columns={columns} onChange={handleChange} />
          </Popup> */}
          <div className="content">
            <h1>欢迎您</h1>
            <h3>登录 Momenta GO司机端{getVersionText(REACT_APP_ENV)}</h3>
            {/* <div className="input-box" onClick={() => setActionFlag(true)}>
              <p className={`text ${!carPlate? 'placeholder' :''}`}>{carPlate || '请选择车辆'}</p>
            </div> */}
            <div className="input-box">
              <input
                ref={phoneRef}
                type="tel"
                placeholder="输入你的手机号"
                value={phone}
                onInput={(e) => {
                  setTipText('');
                  setPhone(e.target.value);
                }}
              />
              <span className="tip">{tipText}</span>
            </div>
            <div className="car-plate-result">车牌号: <span>{plateLicense ? plateLicense : "--"}</span></div>

            { (!plateLicense&&isTimeout) ? <div className="find-result-tips">
                <img src={tipsIcon} alt="tips" />
                <span>未识别到车牌，请联系MGO运营</span>
                <span className="retry-btn" onClick={()=> retry()}>点击重试</span>
              </div> : ""
            }
            {/* <div className="find-result-tips">
              <img src={tipsIcon} alt="tips" />
              <span>PAD与车端未连接，检查网络问题后<span className='reload'>刷新</span></span>
            </div> */}
            <div className={`btn ${disabled ? 'disabled' : ''}`} onClick={handleLogin}>
              登录 {boxid}
            </div>
          </div>
          <div className="bg"></div>
        </div> : 
        <div className="loading">
          资源加载中...
        </div>  
      }
    </>
  );
};

export default memo(Login);
