import { Toast } from 'antd-mobile';
import { MVIZ_URL } from '@/constant/config';
import logSend from '@/utils/aLog';
import store from '@/store';
function isInRobotaxiApp(){
    return navigator.userAgent.indexOf('android_robotaxi_driver_app') != -1
}
// 内存消息提示
window.__momenta_robotaxi__memory__ = function (data) {
    let res = JSON.parse(JSON.stringify(data))
    if(res?.memoryLevel == 5 || res?.memoryLevel == 80){
        Toast.show({
            content: `内存占用紧张code:${res?.memoryLevel}`
        });
        setTimeout(()=>{
            window.location.reload();
        }, 1500);
    }
}
window.__momenta_robotaxi_appliction_state__ = function (data) {
    // console.log(data);
}

window.isReloadEnable = function(){
    let status = store.getState()?.order?.OrderStatus;
    const hasOrder = [3,4,5,6].includes(status);
    return hasOrder ? 0 : 1;
}

/**
 * 调用端的scheme协议，app实现拦截
 * @param {*} methodName
 * @param {*} data
 */
function commonScheme(methodName, data) {
    logSend({
        action: 'JsBridge',
        methodName,
        data,
    });
    if(!isInRobotaxiApp()) return;
    try{
        window.location.href = `jsbridge://${methodName}?${JSON.stringify(data)}`
    }catch(err){
        console.log('commonScheme', err);     
    }
}
// 定义Bridge类型 
// 1、AudioType->音频播放
const AudioType = 'AudioPlayBridge';
// 2、设置JS点击区域
const setHotArea = 'GetJSClickArea';
// 3、设置刷新
const refresh = 'RefreshBridge';
// 4、设置Mviz状态
const ChangeMviz = 'GetLoginStatus';
// 5、关闭native导航设置
const CloseMavtive = 'CloseDialogBridge';
// 6、H5通知端导航信息
const NavInfo = 'NaviBridge';
// 5、设置车牌
const SetPlate = 'getPlate';

// 暴露全局方法给端
commonScheme('GetJSMethodName', {
    data: {
        memoryMethod: '__momenta_robotaxi__memory__',
        appStateMethod: '__momenta_robotaxi_appliction_state__'
    }
});
// 定义暴露使用的对象
export const AppFn = {
    // 音频播放
    AudioPlay: function (playType, cbName) {
        commonScheme(AudioType, {
            data: {
                playType,
                cbName
            }
        });
    },
    // 设置热区
    SetHotArea: function (isAllHot, data) {
        data = data.filter(item => item != '');
        // 全屏热区，区域设置为空
        if(isAllHot){
            commonScheme(setHotArea, {
                data: {
                    data:[],
                    isAllHot
                }
            });
        } else{
            commonScheme(setHotArea, {
                data: {
                    data,
                    isAllHot
                }
            });
        }
    },
    // 刷新容器
    Refresh: function (){
        logSend({
            action: 'flush',
        });
        commonScheme(refresh, {
            data: {
                homeUrl: encodeURIComponent(window.location.href)
            }
        });
    },
    // 展示Mviz
    ShowMivz: function(){
        commonScheme(ChangeMviz, {
            data: {
                isLogin: true, 
                mapArea: "42.0",
                mVizArea: "58.0",
                mVizUrl: encodeURIComponent(MVIZ_URL)
            }
        });
    },
    // 隐藏Mviz
    HideMviz: function(){
        commonScheme(ChangeMviz, {
            data: {
                isLogin: false
            }
        });
    },
    // 关闭Native导航弹窗
    CloseNativeNavSetting: function(){
        commonScheme(CloseMavtive, {
            data: {}
        });
    },
    // 通知端导航信息
    /**
     * @param {*} actionType 
     * @param {*} data {"destination": "北京市海淀区东升大厦","latitude":"XXX", * "longitude":"XXX"}
     */
    NoticeNativeNavInfo: function(actionType, data){
        commonScheme(NavInfo, {
            data: {
                actionType,
                data
            }
        });
    },
    // 与端同步车牌号
    setPlate: function(){
        commonScheme(SetPlate, {
            data: {
                plate: localStorage['momenta_carplate']||""
            }
        });
    },
    playLocalAudio: function (data) {
        window?.momentaJsBridge?.dispatch("playLocalAudio",null,data);
    }
}






