import './index.less';
import { memo, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OrderStatus } from '@/constant/order';
import { CHANGE_ORDER_STATUS } from '@/store/action/type';
import { tripEnd, eventInfoSync } from '@/utils/api';
import { handlerEmpty, isTripStart } from '@/utils/index';
import format from 'date-format';
import DialogCustom from '@/components/common/AlertCommon';
import { AppFn } from '@/utils/JsBrigde';
import { HOT_AREA_TYPE } from "@/store/action/type";
import { fsPush } from "@/utils/request";

const CAR_DRIVING_REMAIN_EVENT_TYPE = 'TRIP_EVENT_TYPE_CAR_DRIVING_REMAIN';
const TRIP_STATE_START = 'TRIP_STATE_START';

let mapClick = {}, Map5m = {}, Map100m = {};
const DriveOff = ({ TRIP_INFO }) => {
  // const { drivingState } = useSelector((state) => state.map);
  // // 获取服务端兜底数据
  // const driving_route = TRIP_INFO?.route?.driving_route?.route_remain;
  const avgSpeed = TRIP_INFO?.route?.speed;
  // 获取智驾成功与否
  // const { routeResult } = useSelector((state) => state.map);
  const isMtour = useSelector(state => state.order.Mtour);
  const etd = useSelector(state => state.etd.etdData);
  // const [tipText, setTipText] = useState('');
  const trip_updated_time = useSelector(state => state.order?.TRIP_INFO?.trip_updated_time);
  const trip_now_time = useSelector(state => state.order?.TRIP_INFO?.trip_now_time);

  const [distance_remain, setDistance_remain] = useState(0);
  const [remaining_time, setRemaining_time] = useState(0);
  const [arriveTime, setArriveTime] = useState("--");
  const pollTimer = useRef();
  const etdRef = useRef(null);
  const dispatch = useDispatch();
  
  useEffect(()=>{
    if(etd >= 0) {
      setDistance_remain(etd);
      setRemaining_time(etd/avgSpeed);
      etdRef.current = {
        distance_remain: etd,
        remaining_time: etd/avgSpeed
      }
    }
  }, [etd]);

  const getPollParams = () => {
    let etdData = etdRef.current;
    return {
      event_type: CAR_DRIVING_REMAIN_EVENT_TYPE,
      trip_id: TRIP_INFO?.trip_id,
      state: TRIP_STATE_START,
      route_remain: {
        distance_remain: etdData?.distance_remain+"", 
        remaining_time: etdData?.remaining_time+""
      }
    }
  }

  useEffect(() => {
    let endDate = (new Date()).getTime();
    let gap = remaining_time * 1000;
    gap = Math.ceil(gap/60000) * 60 * 1000;
    setArriveTime(format("hh:mm", new Date(endDate+gap)));
  }, [remaining_time]);

  useEffect(()=>{
    setInterval(()=>{
      if(!etdRef?.current) return
      let endDate = (new Date()).getTime();
      let gap = etdRef?.current?.remaining_time * 1000;
      gap = Math.ceil(gap/60000) * 60 * 1000;
      setArriveTime(format("hh:mm", new Date(endDate+gap)));
    }, 1000);
  }, [])

  useEffect(() => {
    pollTimer.current = setInterval(() => {
      eventInfoSync(getPollParams())
  }, 3000);
    return () => {
      clearInterval(pollTimer.current);
    };
  }, []);

  function IsArrived(){
    DialogCustom.show({
      msg: '请确认是否到达下车站点',
      onOk: () => {
        next();
      }
    });
  }

  const next = async () => {
    let res = await tripEnd({
      trip_id: TRIP_INFO?.trip_id,
    });
    if (res.code === 0) {
      // 手动点击播放欢迎乘坐Momenta robotaxi welcome
      AppFn.playLocalAudio("arrival.wav");
      dispatch({
        type: CHANGE_ORDER_STATUS,
        data: OrderStatus.ARRIVE_DESTINATION,
      });
      AppFn.NoticeNativeNavInfo('reach_destination',{});
      dispatch({
        type: HOT_AREA_TYPE.HotAll,
        data: false
      });
      mapClick[`${TRIP_INFO?.trip_id}`] = true;
    }
  };
  // 设置tip
  useEffect(() => {
    let TripId = TRIP_INFO?.trip_id;

    // fsPush(distance_remain);

    if ((distance_remain > 0 && distance_remain <= 30) &&
      !Map5m[`${TripId}`] &&
      !mapClick[`${TripId}`]) {
      Map5m[`${TripId}`] = true;
      // TODO: 播放音频
      // 行程已到达 tripOver
      AppFn.playLocalAudio("end.wav");
    }
    
    if ((distance_remain <= 150 && 
      distance_remain > 30) &&
      !Map100m[`${TripId}`] && !mapClick[`${TripId}`]) {
      Map100m[`${TripId}`] = true;
      // setTipText('即将到达');
      // 行程即将到达 tripWillArrive
      AppFn.playLocalAudio("commingsoon.wav");
    }
  }, [distance_remain]);

  return (
    <div className="drive-off-container">
        <div className="TopContent">
            <div className="ContentLeft">
                <div className="TopButton" onClick={IsArrived}>
                    到达下车站点
                </div>
            </div>
            <div className="ContentRight">
                <div className="LastTime">
                    <span className="Number">
                      {Math.ceil(remaining_time/60)}
                    </span>
                    <span className="RightLabel">分钟</span>
                </div>
                <div className="Duration">
                    <span className="Number">
                      {(distance_remain / 1000).toFixed(1)}
                    </span>
                    <span className="RightLabel">公里</span>
                </div>
                <div className="Arravietime">
                    <span className="Number">
                      {arriveTime}
                    </span>
                    <span className="RightLabel">到达</span>
                </div>
            </div>
        </div>
    </div>
  );
};

export default memo(DriveOff);
