import { ROBONODE_TYPE } from '../action/type';

const INIT_STATE = {
  ready: false,
};

export default function robonode(state = INIT_STATE, action) {
  switch (action.type) {
    case ROBONODE_TYPE.ready:
      return { ...state, ...action.data };
    default:
      return state;
  }
}
