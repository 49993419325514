import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckType, CarStateArr } from '@/store/action/type';
import { Switch, Toast } from 'antd-mobile';
import { offDuty } from '@/utils/api';
import { initTabData } from '@/constant/config';
import { OrderStatus } from '@/constant/order';
import {orderClearReset} from "@/utils/DataUtil";
import sw from "./img/switch.png";
import sw_a from "./img/switch_active.png";
import style from './index.module.less';

const goWorkStyle = { 
    '--checked-color': '#FFBA40', 
    '--height': '.16rem', 
    '--width': '.18rem'
};

const { CAR_START, GET_PASSENGER, WAIT_PASSENGER, TRANS_PASSENGER } = OrderStatus;
export default function GoWork() {
    const carState = useSelector(state => state.car.carState);
    const hasCheck = useSelector(state => state.check.hasCheck);
    const workStatus = CarStateArr.indexOf(carState)!=-1;
    const os = useSelector((state) => state.order.OrderStatus);
    const hasOrder = [CAR_START, GET_PASSENGER, WAIT_PASSENGER, TRANS_PASSENGER].includes(os);
    const dispatch = useDispatch();
    const NotifyOffDuty = async () => {
        let offRes = await offDuty();
        if(offRes.code == 0){
            Toast.show({
            content: '已下班'
            });
            orderClearReset(dispatch);
        }
    }
    const StartCheck = () => {
        dispatch({
            type: CheckType.setHasCheck,
            data: true
        });
    }
    function handleWork(){
        dispatch(initTabData);
        if(workStatus && hasOrder){
            return Toast.show('您还有正在服务的订单，不能下班');
        }
        if(workStatus){
            NotifyOffDuty();
            return;   
        }
        if(!workStatus){
            StartCheck();
        }
    }
    return (
        <div className={style.accept} onClick={handleWork}>
            <img src={workStatus ? sw_a : sw} alt="" />
            {/* <Switch
                checked={!workStatus}
                style={goWorkStyle}
            />
            <span className={`${style.Status} ${workStatus?style.active:''}`}>
                {workStatus ? '上班中': '已下班'}
            </span> */}
        </div>
    )
}