
import { SaveEtdType } from '../action/type';
const INIT_STATE = {
    etdData: 0,
};
export default function etd(state = INIT_STATE, action) {
    switch (action.type) {
        case SaveEtdType.saveETD:
            return { ...state, etdData: action.data};
        default:
            return state;
    }
}