// 首页状态
export const OrderStatus = {
  IDEL: 1, // 空闲、下班状态
  LISTEN_ORDER: 2, // 听单状态
  MTOUR_ACCEPT: 14, // mtour订单来了
  CAR_START: 3, // 接单状态
  GET_PASSENGER: 4, // 接驾
  WAIT_PASSENGER: 5, // 等待乘客
  TRANS_PASSENGER: 6, // 送驾
  ARRIVE_DESTINATION: 7, // 到达下车点
  FINISH_ORDER: 8, // 接单结束
  PASSENGER_CANCEL: 9, // 乘客取消 CANCEL
  DRIVER_CANCEL: 10, // 司机取消 RETRY_DISPATCH      
  PASSENGER_TIMEOUT: 11, // 乘客超时取消 CANCEL
  DRIVER_OFFLINE: 12, // 司机离线 RETRY_DISPATCH
  NO_VALID_CAR: 13 // 没有可用车辆 CANCEL
};