import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { emit } from '@/utils/robonode';
import { SocketMap } from '@/constant/socketMap';
import CoordTransform from '@/utils/CoordTransform';
import { CheckType, CarStateArr} from '@/store/action/type';
import MmtTips from '../MmtTips/index';
import { onDuty } from '@/utils/api';
import { SaveRouteInfo } from '../../../utils/DataUtil';

const checkStation = {
    up: {
      lon: '120.637974507',
      lat: '31.4202700435',
      name: 'Momenta大厦'
    },
    down: {
      lon: '120.638311',
      lat: '31.419581',
      name: '天成时代广场'
    }
}
function getOrgsAndDest(checkStation){
    const {up, down} = checkStation;
    return {
        orgPoint: CoordTransform.wgs84ToGcj02(up?.lon, up?.lat),
        dstPoint: CoordTransform.wgs84ToGcj02(down?.lon, down?.lat)
    }
}
function getCheckParam() {
    let {orgPoint,dstPoint} = getOrgsAndDest(checkStation);
    const point = {
        address: '',
        addressDesc: '',
    }, base = {
        startDrive: 1,
        type: 1,
        viaAddress: [], 
    }
    return {
        ...base,
        orgAddress: {
            ...point,
            lon: orgPoint[0],
            lat: orgPoint[1],
        },
        dstAddress: {
            ...point,  
            lon: dstPoint[0],
            lat: dstPoint[1],
        }
    }
}

const CheckRetryGap = 8;
let time = CheckRetryGap, spendSum = 0;
export default function CarCheck() {
    const hasCheck = useSelector((state) => state.check.hasCheck);
    const tryTimer = useRef(null);
    const dispatch = useDispatch();
    const sendCheck = async () => {
        return await emit(SocketMap.routePlanning, getCheckParam());
    }
    const notifyOnDuty = async () => {
        MmtTips.close();
        let res = await onDuty();
        if(res.code == 0){
            successTip();
            doneHandle();
        }
    }
    const tryCheck = async ()=> {
        let CheckRes = null;
        countRetry();
        CheckRes = await sendCheck();
        if(CheckRes?.result?.gps_points?.length){
            notifyOnDuty();
        }else{
            failTip();
            doneHandle();
        }
    }
    const countRetry = () => {
        spendSum += time;
        tryTimer.current = setInterval(()=>{
            if(spendSum >= 60){
                failTip();
                doneHandle();
                return;
            }
            if(time<1){
                if(tryTimer.current){
                    doneHandle();
                }
                tryCheck();
            }
            time--;
        }, 1000);
    }
    const resetCheckData = () => {
        SaveRouteInfo(dispatch,{});
        dispatch({
            type: CheckType.setHasCheck,
            data: false
        });
    }
    const doneHandle = () => {
        clearInterval(tryTimer.current);
        time = CheckRetryGap;
        resetCheckData();
    }
    const failTip = () => {
        spendSum = 0;
        setTimeout(()=>{
            MmtTips.show({
                type: 'checkFail'
            });
        }, 2000);
    }
    const successTip = () => {
        MmtTips.show({
            type: 'checkSuccess'
        });
    }
    useEffect(async ()=> {
        if(hasCheck){
            notifyOnDuty();
            // MmtTips.show({type: 'checkDoing'});
            // try {
            //     tryCheck();
            // } catch {
            //     failTip();
            //     doneHandle();
            // }
        }
    }, [hasCheck]);
    return <></>;
}