import { HOT_AREA_TYPE } from '../action/type';

const INIT_STATE = {
    // 导航区域
    NavArea: '', 
    // 刷新区域
    ReloadArea: '', 
    // 订单区域
    CardArea: '', 
    // 接续接单区域
    GoOnArea: '',
    // 今日订单热区
    TodayOrder: '',
    // 订单列表
    OrderList: '',
    // 是否为全屏热区
    IsHotAll: false, 
};

export default function hotArea(state = INIT_STATE, action) {
    switch (action.type) {
      case HOT_AREA_TYPE.Nav:
        return {...state, NavArea: action.data};
      case HOT_AREA_TYPE.Reload:
        return {...state, ReloadArea: action.data};
      case HOT_AREA_TYPE.Card:
        return {...state, CardArea: action.data};
      case HOT_AREA_TYPE.GoOn:
        return {...state, GoOnArea: action.data};
      case HOT_AREA_TYPE.TodayOrder:
        return {...state, TodayOrder: action.data};
      case HOT_AREA_TYPE.OrderList:
        return {...state, OrderList: action.data};  
      case HOT_AREA_TYPE.HotAll:
        return {...state, IsHotAll: action.data};
      default:
        return state;
    }
  }