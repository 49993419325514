import { CheckType } from '../action/type';
const INIT_STATE = {
  workStatus: 1 , // 0 - off, 1 - on
  hasCheck: false
};
export default function check(state = INIT_STATE, action) {
    switch (action.type) {
      case CheckType.setStatus:
        return { ...state, workStatus: action.data };
      case CheckType.setHasCheck:
        return { ...state, hasCheck: action.data };   
      default:
        return state;
    }
  }