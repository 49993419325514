import { useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrderStatus, MAP_TYPE } from '@/store/action/type';
import { OrderStatus } from '@/constant/order';
import {
  carArrived, 
  drivingRouteSync,
} from '@/utils/api';
import RouteStatus from '@/components/common/RouteStatus';
import { Toast } from 'antd-mobile';
import DialogCustom from '@/components/common/AlertCommon';
import { AppFn } from '@/utils/JsBrigde';
import { getArriveTime } from "@/utils/index";
import { fsPush } from "@/utils/request";

import {
  getOrgsAndDest,
  ETA_EMPTY, 
  handleETA,
  getOrgAndDstName
} from "./fn.js";
import Style from './index.module.less';

export default function GetPassenger({ TRIP_INFO }) {
  const isMtour = useSelector(state => state.order.Mtour);
  const pass_point_json = TRIP_INFO?.route?.pass_point_json;
  const trip_points = useSelector((state) => state.order?.point);
  const [planTime, setPlanTime] = useState(0);
  const [isPlanning, setIsPlanning] = useState(false);
  const [ETA, setETA] = useState({
    ...ETA_EMPTY
  });
  const [arriveTime, setArriveTime] = useState("--");
  const dispatch = useDispatch();
  let timeRef = useRef();
  let ETARef= useRef(null);
  let trip_id = TRIP_INFO?.trip_id;

  useEffect(()=>{
    // 获取导航信息
    window.naviInfoMethod = function(data){
      ETARef.current = data;
    };

    window.nativeRoutingResult = async (data) => {
      data = JSON.parse(JSON.stringify(data));
      endPlanning();
      // 2、3 超时和失败
      if(data.code == 2 || data.code == 3){
        handlePlanningFail();
        return;
      }
      handlePlanSuccess();
      drivingRouteSync({
        route: {
          distance: data?.distance,
          gps_points: data?.result
        },
        trip_id: parseInt(trip_id),
      });
    }
  }, []);

  function IsArrive(){
    if(isPlanning) return Toast.show('路径规划中, 请稍后操作~');
    DialogCustom.show({
      msg: '请确认是否到达上车站点',
      onOk: () => {
        AppFn.NoticeNativeNavInfo('arrive_pickup_point',{});
        carRoutePlanning();
      }
    });
  }
  function IsResumePlan(){
    clear();
    DialogCustom.show({
      msg: '是否需要重新进行路径规划？',
      onOk: async () => {
        carRoutePlanning();
      },
      onCancel: async () => {
        await carArrivedHandler();
      }
    });
  }
  
  const getParams = ()=> {
    let {org, dst} = getOrgsAndDest(TRIP_INFO);
    let { start_name, end_name} = getOrgAndDstName(trip_points);
    if(isMtour){
      return {
        startName: start_name,
        endName: end_name,
        ...JSON.parse(pass_point_json)
      }
    }
    return {
      startName: start_name,
      endName: end_name,
      comment: "Robotaxi点到点路线",
      pntCnt: 2,
      pnts: [
        {
          "x": org?.point?.lon,
          "y": org?.point?.lat,
          "z": 0
        },
        {
          "x": dst?.point?.lon,
          "y": dst?.point?.lat,
          "z": 0
        }
      ]
    }
  }

  const carRoutePlanning = () => {
    startPlanning();
    window?.momentaJsBridge?.dispatch("postRouting","nativeRoutingResult", JSON.stringify(getParams()))
  }

  const carArrivedHandler = async () => {
    let arrivedRes = await carArrived({ trip_id });
    if (arrivedRes.code === 0) {
      AppFn.NoticeNativeNavInfo('arrive_pickup_point',{});
      dispatch(updateOrderStatus(OrderStatus.WAIT_PASSENGER));
    }
  }

  const startPlanning = () => {
    setIsPlanning(true);
    dispatch({
      type: MAP_TYPE.routeLoading,
      data: true,
    });
    // timeRef.current = setInterval(()=>{
    //   setPlanTime((n = 0)=> {
    //     return n + 1;
    //   });
    // }, 1000);
  }
  const endPlanning = () => {
    setIsPlanning(false);   
    dispatch({
      type: MAP_TYPE.routeLoading,
      data: false,
    });
  }
  
  const clear =()=>{
    clearTimeout(timeRef.current);
    setPlanTime(0);
    setIsPlanning(false);
  }
  const handlePlanSuccess = () => {
    dispatch({
      type: MAP_TYPE.routeResult,
      data: true
    })
    RouteStatus.show({
      type: 'success'
    });
    setTimeout(async()=>{
      await carArrivedHandler();
    }, 1000);
  }
  
  const handlePlanningFail = () => {
    clear();
    RouteStatus.show({
      type: 'fail'
    });
    dispatch({
      type: MAP_TYPE.routeLoading,
      data: false,
    });
    setTimeout(()=>{
      IsResumePlan();
    }, 1000);
  }
  
  // useEffect(() => {
  //   // 5s自动超时
  //   if(planTime>5) {
  //     handlePlanningFail();
  //   }
  // }, [planTime]);

  useEffect(()=>{
    setInterval(()=>{
      setArriveTime(getArriveTime(ETARef?.current?.RemainingTime));
    }, 1000);
  }, [])

  
  return (
    <div className={Style.Container}>
        <div className={Style.TopContent}>
            <div className={Style.ContentLeft}>
                <div className={Style.TopButton} onClick={IsArrive}>
                    到达上车站点
                </div>
            </div>
            <div className={Style.ContentRight}>
                <div className={Style.LastTime}>
                    <span className={Style.Number}>{ETARef?.current?.RemainingTime || "--"}</span>
                    <span className={Style.RightLabel}>分钟</span>
                </div>
                <div className={Style.Duration}>
                    <span className={Style.Number}>{ETARef?.current?.RemainingDistance || "--"}</span>
                    <span className={Style.RightLabel}>公里</span>
                </div>
                <div className={Style.Arravietime}>
                    <span className={Style.Number}>{arriveTime}</span>
                    <span className={Style.RightLabel}>到达</span>
                </div>
            </div>
        </div>
    </div>
  );
}