import { MAP_TYPE } from '../action/type';
const INIT_STATE = {
  // 定位信息
  locateInfo: {},
  // 智驾路线信息
  routeInfo: {},
  // 行驶状态
  drivingState: {},
  // 智驾路线规划loading
  routeLoading: false,
  // 智驾规划结果
  routeResult: false,
  // 展示h5地图
  showH5Map: false
};

export default function map(state = INIT_STATE, action) {
  switch (action.type) {
    case MAP_TYPE.locateInfo:
      return { ...state, locateInfo: action.data };
    case MAP_TYPE.route:
      return { ...state, routeInfo: action.data };
    case MAP_TYPE.drivingState:
      return { ...state, drivingState: action.data };
    case MAP_TYPE.routeLoading:
      return { ...state, routeLoading: action.data };
    case MAP_TYPE.routeResult:
      return { ...state, routeResult: action.data }; 
    case MAP_TYPE.showH5Map:
      return { ...state, showH5Map: action.data}; 
    default:
      return state;
  }
}
