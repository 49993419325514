import { OrderStatus } from '@/constant/order';
import { 
  CHANGE_ORDER_STATUS, 
  SAVE_TRIP_INFO,
  SAVE_PASSENGER_INFO,
  SAVE_TRIP_TYPE,
  SAVE_TRIP_POINT,
  SAVE_RESERVE_TRIP
 } from '../action/type';

const INIT_STATE = {
  OrderStatus: OrderStatus.LISTEN_ORDER, // 默认是接单状态
  TRIP_INFO: null,
  trip_point: null,
  user: null,
  Mtour: false,
  RESERVE_TRIP: null
};

export default function order(state = INIT_STATE, action) {
  switch (action.type) {
    case CHANGE_ORDER_STATUS:
      return { ...state, ...{ OrderStatus: action.data } };
    case SAVE_TRIP_INFO:
      return { ...state, ...{ TRIP_INFO: action.data } };
    case SAVE_PASSENGER_INFO:
      return { ...state, ...{ PASSENGER_INFO: action.data }}  
    case SAVE_TRIP_TYPE:
      return { ...state, ...action.data}
    case SAVE_TRIP_POINT:  
      return { ...state, ...action.data}
    case SAVE_RESERVE_TRIP:
      return { ...state, ...{ RESERVE_TRIP: action.data}}
    default:
      return state;
  }
}
