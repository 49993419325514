import Socket from './socket';
import store from '@/store';
import { Toast } from 'antd-mobile';
import { SocketMap } from '@/constant/socketMap';
import { ROBONODE_TYPE, MAP_TYPE } from '@/store/action/type';
import { ROBONODE_WSS } from '@/constant/config';
import logSend from '@/utils/aLog';
import { NotifyAppLocation } from "@/utils/index";
import CoordTransform from '@/utils/CoordTransform';

const emitHeap = new Map();
const PRE_DISCONNECT_SN = 'remove';
let emitLock = false;
let reConnectTimer = null;

const baseData = {
  type: 3,
  id: 'hmi',
  sn: 'hmimomenta',
};
const socket = new Socket(ROBONODE_WSS, {});

function throttle(fn, delay) {
  let timer = null
  return function () {
      if(timer) return
      timer = setTimeout(() => {
        fn.apply(this,arguments)
        timer = null
      }, delay);
  }
}

function saveLocation(data) {
  store.dispatch({
    type: MAP_TYPE.locateInfo,
    data: data,
  });
}

let sendFn = throttle(saveLocation, 1000);


function sendRoboLog(cmd, data) {
  const logMap = {
    "9": "NotifyRoboRoutePlanning",
    "10": "NotifyRoboStartAutopilot"
  };
  if(!logMap[cmd]) return;
  logSend({
    action: logMap[cmd],
    cmd, 
    data
  });
}

function reciveRoboLog(data) {
  const { cmd, sn } = data;
  const logMap = {
    '2': 'recive_robo_locateInfo',
    '6': 'recive_robo_drivingState',
    '9': 'recive_robo_routePlanning',
    '10': 'recive_robo_startAutopilot',
    '12': 'recive_robo_terminus',
    '13': 'recive_robo_travelStatistics',
    '15': 'recive_robo_manualTerminus',
    '16': 'recive_robo_syncOrderStatus',
    'mtour_msd_query': 'recive_robo_mtour_query',
    'heartbeat': 'recive_robo_heartbeat'
  };
  logSend({
    action: logMap[cmd] || 'recive_robo_other',
    cmd, 
    sn,
    data
  });
}
socket.onConnect(() => {
  console.log('$$connect');
  store.dispatch({
    type: ROBONODE_TYPE.ready,
    data: { ready: true },
  });
  preDisconnect();
});
socket.onMessage((data) => {
  const { cmd, sn } = data;
  reciveRoboLog(data);
  switch (data.cmd) {
    case "current_state":
      let locateInfo = data?.data?.position;
      let point = CoordTransform.wgs84ToGcj02(locateInfo?.lon, locateInfo?.lat);
      locateInfo.lon = point[0];
      locateInfo.lat = point[1];
      let HMI_Location = {
        ...locateInfo,
        speeding: data?.data?.speeding/3.6,
        heading: data?.data?.heading,
        position_state: data?.data?.position_state,
        boxid: data?.data?.boxid
      }
      sendFn(HMI_Location);
      // saveLocation(HMI_Location);
      handlePreDisconnect();
      break;
    // 心跳
    case SocketMap.heartbeat:
      handlePreDisconnect();
      break;
    // 定位
    case SocketMap.locateInfo:
      // console.log('----robonode locateInfo-----', data);
      NotifyAppLocation(data.data);
      store.dispatch({
        type: MAP_TYPE.locateInfo,
        data: data.data,
      });
      handlePreDisconnect();
      break;
    // 路线规划
    case SocketMap.routePlanning:
    case SocketMap.mtourRoute:
      // console.log('----robonode routePlanning-----', data);
      store.dispatch({
        type: MAP_TYPE.route,
        data: data.data.result,
      });
      break;
    // 实时行驶状态信息
    case SocketMap.drivingState:
      // console.log('------robonode drivingState-------', data.data);
      store.dispatch({
        type: MAP_TYPE.drivingState,
        data: data.data,
      });
      break;
    // 到达终点
    case SocketMap.terminus:
      handleSucMsg({ cmd, sn });
      // TODO: 通知
      break;
    // 行程统计
    case SocketMap.travelStatistics:
      handleSucMsg({ cmd, sn });
      break;
    default:
      break;
  }
  handleResponse(data);
});

/**
 * 向robonode推送事件
 * @param {number} type 向robonode发送的事件
 * @param {object} data 发送的数据
 */
function emit(cmd, data) {
  const robonodeState = store.getState()?.robonode || {};
  if (!robonodeState.ready) {
    Toast.show({
      content: 'robonode未连接...',
      duration: 2000,
    });
    throw new Error('robonode未连接');
  }
  const sn = baseData.sn + Date.now();
  const params = {
    ...baseData,
    sn,
    cmd,
    data,
  };
  sendRoboLog(cmd, params);
  return emitWrapPromise(params);
}

/**
 * 向robonode响应成功
 * @param {string} cmd 消息类型
 * @param {string} sn 本次消息唯一标识
 */
function handleSucMsg({ cmd, sn }) {
  socket.send({
    ...baseData,
    cmd,
    sn,
    data: { code: 0, msg: 'ok' },
  });
}
/**
 * promise包装请求robonode监听响应
 * @param {object} params 请求robonode发送的数据
 * @returns {promise<object>}
 */
function emitWrapPromise(params) {
  const { sn } = params;
  return new Promise((resolve, reject) => {
    emitHeap.set(sn, {
      count: 1,
      params,
      success: resolve,
      fail: reject,
    });
    socket.send(params);
    // emitLoop();
  });
}
/**
 * 处理robonode响应数据
 * @param {object} res robonode响应的数据
 */
function handleResponse(res) {
  const { sn, data } = res;
  if (!emitHeap.has(sn)) return;
  const { success } = emitHeap.get(sn);
  success(data);
  emitHeap.delete(sn);
}

/**
 * 重试没有响应的数据
 */
function emitLoop() {
  if (emitLock) return;
  emitLock = true;
  if (!emitHeap.size) {
    emitLock = false;
    return;
  }
  setTimeout(() => {
    for (let val of emitHeap.values()) {
      const { count, params } = val;
      // 重试上限
      if (count >= 15) {
        disconnect();
        break;
      }
      val.count++;
      socket.send(params);
    }
    emitLock = false;
    emitLoop();
  }, 3000);
}

/**
 * 断开连接处理
 */
function disconnect() {
  console.log('$$disconnect');
  emitHeap.forEach(({ params, fail }) => {
    Toast.show({
      content: '车辆Robonode超时断开...',
      duration: 2000,
    });
    fail({ code: -1, msg: '超时' });
  });
  emitHeap.clear();
  store.dispatch({
    type: ROBONODE_TYPE.ready,
    data: { ready: false },
  });
  socket.reconnect();
}
/**
 * 开发环境使用
 * 断开robomode连接
 */
function close() {
  emitHeap.clear();
  socket.close();
}

/**
 * 建立连接后断开上次连接
 */
function preDisconnect() {
  emitWrapPromise({ type: 3, id: 'hmi', sn: PRE_DISCONNECT_SN });
}
/**
 * 通过推送gps信息来判断断开上次连接发送成功
 */

function handlePreDisconnect() {
  clearInterval(reConnectTimer)
  reConnectTimer = setInterval(() => {
    disconnect();
  }, 5000);
  if (!emitHeap.has(PRE_DISCONNECT_SN)) return;
  emitHeap.delete(PRE_DISCONNECT_SN);
}

export { emit, close, disconnect, handlePreDisconnect};
