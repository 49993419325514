import CoordTransform from '@/utils/CoordTransform';

export function getOrgsAndDest(TRIP_INFO){
    let org = null;
    let dst = null;
    TRIP_INFO?.trip_points.forEach(item => {
        if(item?.trip_point_type === 'TRIP_POINT_TYPE_UP_STATION'){
            org = item;
        }
        if(item?.trip_point_type === 'TRIP_POINT_TYPE_DOWN_STATION'){
            dst = item;
        }
    });
    return {
        org,
        dst
    }
}

export function transPoint(org, dst){
    return {
        orgPoint: CoordTransform.wgs84ToGcj02(org?.point?.lon, org?.point?.lat),
        dstPoint: CoordTransform.wgs84ToGcj02(dst?.point?.lon, dst?.point?.lat)
    }
}

export function getMtourParam(TRIP_INFO, robo_route_id){
    let {org,dst} = getOrgsAndDest(TRIP_INFO);
    const point = {
        address: '',
        addressDesc: '',
    }, base = {
        startDrive: 1,
        type: 1,
        viaAddress: [], 
    }
    return {
        ...base,
        orgAddress: {
            ...point,
            lon: org[0],
            lat: org[1],
        },
        dstAddress: {
            ...point,  
            lon: dst[0],
            lat: dst[1],
        },
        path: robo_route_id
    };
}

export function getBaseParam(TRIP_INFO) {
    let {org, dst} = getOrgsAndDest(TRIP_INFO);
    let {orgPoint, dstPoint} = transPoint(org, dst);
    const point = {
        address: '',
        addressDesc: '',
    }, base = {
        startDrive: 1,
        type: 1,
        viaAddress: [], 
    }
    return {
        ...base,
        orgAddress: {
            ...point,
            lon: orgPoint[0],
            lat: orgPoint[1],
        },
        dstAddress: {
            ...point,  
            lon: dstPoint[0],
            lat: dstPoint[1],
        }
    }
}

export const ETA_EMPTY = {
    RemainingDistance: '--',
    RemainingTime: '--',
    ArrivalTime: '--'
}
export function handleETA(data, setETA){
    let {
        RemainingDistance,
        RemainingTime,
        ArrivalTime
    } = JSON.parse(JSON.stringify(data));
    setETA({
        RemainingTime,
        RemainingDistance,
        ArrivalTime
    });
}

export function getOrgAndDstName(trip_points){
    let start_name, end_name;
    trip_points?.forEach(item=>{
        if(item?.trip_point_type === 'TRIP_POINT_TYPE_UP_STATION'){
        start_name = item?.name;
        } 
        if(item?.trip_point_type === 'TRIP_POINT_TYPE_DOWN_STATION'){
        end_name = item?.name;
        }
    });
    return {
        start_name,
        end_name
    }
}