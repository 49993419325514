// actionTypes
export const CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS'; // 改变订单状态
export const SAVE_TRIP_INFO = 'SAVE_TRIP_INFO'; // 保存TRIP_INFO
export const SAVE_PASSENGER_INFO = 'SAVE_PASSENGER_INFO'; // 保存乘客信息
export const SAVE_TRIP_TYPE  = 'SAVE_TRIP_TYPE'; // 保存Mtour订单标示
export const SAVE_CAR_STATE = 'SAVE_CAR_STATE';
export const SAVE_TRIP_POINT = 'SAVE_TRIP_POINT';
export const SAVE_RESERVE_TRIP = 'SAVE_RESERVE_TRIP';

// 更新订单状态
export const updateOrderStatus = (data) => ({
  type: CHANGE_ORDER_STATUS,
  data,
});

export const ROBONODE_TYPE = {
  ready: 'ready',
};

export const MAP_TYPE = {
  locateInfo: 'locateInfo',
  route: 'route',
  drivingState: 'drivingState',
  routeLoading: 'routeLoading',
  routeResult: 'routeResult',
  showH5Map: 'showH5Map'
};

export const saveReserveTrip = (data) => ({
  type: SAVE_RESERVE_TRIP,
  data,
});
// 保存行程信息
export const saveTripInfo = (data) => ({
  type: SAVE_TRIP_INFO,
  data,
});

// 保存乘客信息
export const savePassengerInfo = (data) => ({
  type: SAVE_PASSENGER_INFO,
  data,
});

// 保存Mtour订单标示
export const saveTripType = (data) => ({
  type: SAVE_TRIP_TYPE,
  data,
});

export const saveCarState = (data) => ({
  type: SAVE_CAR_STATE,
  data
});

export const savePointInfo = (data) => ({
  type: SAVE_TRIP_POINT,
  data
});

export const CarState = {
  off: 'off', // 离线
  prepare: 'prepare', //准备
  idle: 'idle', // 空闲
  working: 'working',
  breathing: 'breathing' 
}
export const CarStateArr = ['idle', 'working', 'breathing'];

// 热区事件type
export const HOT_AREA_TYPE = {
  Nav: 'nav', // 导航区域
  Reload: 'reload', // 刷新区域
  Card: 'card', // 订单区域
  GoOn: 'GoOn', // 继续接单,
  TodayOrder: 'TodayOrder', // 继续接单
  OrderList: "OrderList",
  HotAll: 'hotAll', // 是否为全屏热区
};

export const CheckType = {
  setStatus: 'setStatus',
  setHasCheck: 'setHasCheck'
}

export const SaveEtdType = {
  saveETD: 'saveETD'
}

