import { SliderData, sliderFontSize } from '@/constant/config';
import { useSelector, useDispatch } from 'react-redux';
import { HOT_AREA_TYPE } from '@/store/action/type';

import mapIcon from "./img/map.png";
import mapIconA from "./img/map_active.png";
import orderIcon from "./img/order.png";
import orderIconA from "./img/order_active.png";
import userIcon from "./img/user.png";
import userIconA from "./img/user_active.png";

import style from './index.module.less';
const {flexCenter, active} = style;

function getIcon(type){
  switch(type){
    case "map":
      return mapIcon;
    case "map_a":
      return mapIconA; 
    case "order":
      return orderIcon;
    case "order_a":
      return orderIconA; 
    case "user":
      return userIcon;
    case "user_a":
      return userIconA;      
  }
}
export default function SliderList(){
    const curTab =  useSelector((state) => state.slider.CurTab);
    const dispatch = useDispatch();
    const setHotAreaAll = ()=> {
      dispatch({
          type: HOT_AREA_TYPE.HotAll,
          data: false
      });
    }
    const setCurTab = (data)=> {
        dispatch({
            type: 'CHANGE_TAB',
            data: data,
        });
    }
    const handleTap = (component) => {
        if(curTab == component)return;
        setHotAreaAll();
        setCurTab(component);
    }
    if(!SliderData.length) return <></>;
    return <ul>
      { SliderData.map((item, index)=> 
          <li key={index} 
            className={`${flexCenter}`} 
            onClick={()=> handleTap(item.component)}
            >
              <img src={getIcon(`${item.component}${curTab === item.component ? "_a":""}`)} alt="icon" />
          </li>
        )
      }
    </ul>
  }