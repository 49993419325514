
import { SAVE_CAR_STATE } from '../action/type';
const INIT_STATE = {
  carState: 'off' , // off-离线 prepare-准备 idle-空闲 working-工作中 breathing-呼吸态.
};
export default function check(state = INIT_STATE, action) {
    switch (action.type) {
      case SAVE_CAR_STATE:
        return { ...state, carState: action?.data?.car_state};
      default:
        return state;
    }
  }