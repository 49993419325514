import {useSelector} from 'react-redux';
import IconInfo from '@/assets/img/orderComplate/icon-info.png'
import { OrderStatus } from '@/constant/order';
import GoOnWork from '../goOnWork/index.js';
import Style from './index.module.less';

export default function CancelOrder({TRIP_INFO}){
    const order = useSelector((state) => state.order)
    const isMtour = useSelector(state => state.order.Mtour);
    return <div className={Style.Container}>
       <div className={Style.OrderStatus}>
           <img className={Style.IconInfo} src={IconInfo}></img>
           {order.OrderStatus === OrderStatus.PASSENGER_CANCEL? <span className={Style.TextInfo}>乘客已取消</span> :''}
           {order.OrderStatus === OrderStatus.DRIVER_CANCEL? <span className={Style.TextInfo}>订单已取消</span> : ''}
           {order.OrderStatus === OrderStatus.PASSENGER_TIMEOUT ? <span className={Style.TextInfo}>乘客超时已取消</span> : ''}
           {order.OrderStatus === OrderStatus.DRIVER_OFFLINE ? <span className={Style.TextInfo}>司机离线取消</span> : ''}
           {order.OrderStatus === OrderStatus.NO_VALID_CAR ? <span className={Style.TextInfo}>没有可用车辆取消</span> : ''}
       </div>
       {
           isMtour ? <div className={Style.addressBox}>
            <div className={`${Style.station} ${Style.up}`}>
                <label className={Style.stationLabel}>路线</label>
                <p className={Style.stationParag}>{TRIP_INFO?.route?.name || 'Mtour路线'}</p>
            </div>
            <div className={Style.station}>
                <label className={Style.stationLabel}>上下车站点</label>
                <p className={Style.stationParag}>{TRIP_INFO?.TRIP_POINT_TYPE_UP_STATION}-{TRIP_INFO?.TRIP_POINT_TYPE_DOWN_STATION}</p>
            </div>
        </div> : 
        <div className={Style.OrderInfo}>
           <div className={Style.InfoItem}>
                <label className={Style.InfoItemLabel}>上车站点</label>
                <p className={Style.InfoItemText}>{TRIP_INFO?.TRIP_POINT_TYPE_UP_STATION}</p>
           </div>
           <div className={Style.InfoItem}>
                <label className={Style.InfoItemLabel}>下车站点</label>
                <p className={`${Style.InfoItemText} ${Style.BorderNone}`}>{TRIP_INFO?.TRIP_POINT_TYPE_DOWN_STATION}</p>
           </div>
         </div>
       }
       <div className={Style.Btn}>
            <GoOnWork TRIP_INFO={TRIP_INFO}></GoOnWork>
        </div>  
    </div>
}