const SocketMap = {
  // GPS定位信息
  locateInfo: '2',
  // 实时行驶状态信息
  drivingState: '6',
  // 路线规划
  routePlanning: '9',
  // 启动自动驾驶
  startAutopilot: '10',
  // 到达目的地
  terminus: '12',
  // 行程统计
  travelStatistics: '13',
  // 手动到达目的地
  manualTerminus: '15',
  // 车辆订单状态同步
  syncOrderStatus: '16',
  // Mtour路径规划
  mtourRoute: 'mtour_msd_query',
  // 心跳
  heartbeat: 'heartbeat'
};

// 车辆订单状态同步
const CarOrderStatus = {
  IDLE: 0, // 空闲
  LISTEN: 1, // 听单中
  GET_PASSENGER: 2, // 去上车点 接乘客
  WAIT_PASSENGER: 3, // 等乘客
  TRANS_PASSENGER: 4, // 送乘客
  TRIP_OVER: 5 // 行程结束
}

export {
  SocketMap,
  CarOrderStatus
}
