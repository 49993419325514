import { initTabData } from '@/constant/config';
import OrderClass from './orderClass';
import {
  API_DATA, 
  SaveTripTypeFn,
  SaveTripInfoFn,
  HideRouteLoading,
  savePassengerInfoFn,
  updateOrderStatusFn,
  NoticeNativeFn,
  updateFailRoute,
  SaveRouteInfo,
  SaveCarStateFn,
  SavePointFn,
  orderClearReset,
  SaveReserveTrip
} from "@/utils/DataUtil";
import { AppFn } from '@/utils/JsBrigde';
import { removeDialog, NotifyAppRecoverRoute } from "@/utils/index";
import { getOrgAndDstName } from "@/components/common/getPassenger/fn";

export class RoboTaxiService {
    static clearReport = false;
    constructor(dispatch){
      this.orderInfo = null;
      this.dispatch = dispatch;
    }
    updateOrderData(data){
      this.orderInfo = new OrderClass(data);
    }
    changeOrderStatus(newData) {
      let newOrderData = new OrderClass(newData);
      SaveTripInfoFn(this.dispatch, newOrderData?.trip_info);
      SaveTripTypeFn(this.dispatch, newOrderData?.trip_type);
      SaveCarStateFn(this.dispatch, newOrderData?.car_state);
      SavePointFn(this.dispatch, newOrderData?.trip_points);
      SaveReserveTrip(this.dispatch, newOrderData?.reserve_trip);
      newOrderData?.user && savePassengerInfoFn(this.dispatch, newOrderData?.user);
      if(newOrderData?.order_status == this.orderInfo?.order_status){
        return;
      }
      this.updateOrderData(newData);
      this.handleOrderStatus(newOrderData);
    }
    handleOrderStatus(newData){
      let { order_status, order_status_ext, driving_route, trip_points, trip_type, trip_type_ext} = this.orderInfo;
      updateOrderStatusFn(this.dispatch, newData?.order_status, order_status_ext);
      let recoverRouteData= {
        startName: getOrgAndDstName(trip_points)?.start_name,
        endName: getOrgAndDstName(trip_points)?.end_name,
        pnts: []
      }
      switch(order_status) {
        case API_DATA.IDLE:
          orderClearReset(this.dispatch);
          break;
        case API_DATA.CAR_START:
          NoticeNativeFn('start_navi', newData);
          if(trip_type== "TRIP_TYPE_MTOUR" && trip_type_ext==="TRIP_TYPE_EXT_RESERVE") return; 
          AppFn.playLocalAudio("neworder.wav");
          break;
        case API_DATA.WAIT:
          if(driving_route?.gps_points?.length){
            SaveRouteInfo(this.dispatch, driving_route);
            recoverRouteData.pnts = driving_route?.gps_points;
          } else {
            updateFailRoute(this.dispatch, newData);
          }
          NotifyAppRecoverRoute(recoverRouteData);
          break;
        case API_DATA.START:
          if(driving_route?.gps_points?.length){
            SaveRouteInfo(this.dispatch, driving_route);
            recoverRouteData.pnts = driving_route?.gps_points;
          } else {
            updateFailRoute(this.dispatch, newData);
          }
          NotifyAppRecoverRoute(recoverRouteData);
          break;
        case API_DATA.CANCEL:
          this.dispatch(initTabData);
          removeDialog();
          if(order_status_ext === 'PASSENGER_CANCEL'){
            AppFn.playLocalAudio("cancelorder.wav");
          }
          NoticeNativeFn('cancel_order', newData);
          HideRouteLoading(this.dispatch);
          break;
        case API_DATA.RETRY_DISPATCH: 
          removeDialog();
          break;
        default:
          break;
      }
    }
  }