import React from "react";
import ReactDOM from "react-dom";
import './index.less';
const mTipConfig = {
    success: {
        status: 'mmt-tips-success',
        spin: 'spinner-success',
        text: '智驾规划成功'
    },
    fail: {
        status: 'mmt-tips-fail',
        spin: 'spinner-fail',
        text: '智驾规划失败'
    },
    planning: {
        status: 'mmt-tips-doing',
        spin: 'spinner-doing',
        text: '智驾规划中...'
    },
    checkSuccess: {
        status: 'mmt-tips-success',
        spin: 'spinner-success',
        text: '系统准备成功'
    },
    checkFail: {
        status: 'mmt-tips-fail',
        spin: 'spinner-fail',
        text: '系统准备失败'
    },
    checkDoing: {
        status: 'mmt-tips-doing',
        spin: 'spinner-doing',
        text: '系统准备中...'
    },
}
class MmtTips extends React.Component {
    static roots = [];
    static show = params => {
        MmtTips.close();
        let container = document.createElement("div");
        container.setAttribute('name', 'mmt-tips');
        MmtTips.roots.push(container);
        let main = document.getElementById('main');
        main && main.appendChild(container);
        ReactDOM.render(<MmtTips {...params} Hide={MmtTips.close} />, container);
    }
    static close = () => {
        MmtTips.roots.forEach(item => {
            item.parentNode?.removeChild(item);
        });
    }
    constructor(props) {
        super(props);
    }
    getType(type) {
        return ['checkSuccess', 'success', 'fail', 'checkFail'].indexOf(type) != -1;
    }
    componentDidMount(){
        const { type, Hide } = this.props;
        if(this.getType(type)){
            setTimeout(()=>{
                Hide()
            }, 2000);
        }
    }
    render() {
        const { type } = this.props;
        return (
            <div className={`mmt-tips ${mTipConfig[type].status}`}>
                <span className={`spinner ${mTipConfig[type].spin}`}></span>
                {mTipConfig[type].text}
            </div>
        )
    }
}
export default MmtTips;