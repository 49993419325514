import { OrderStatus } from '@/constant/order';
import {
  CHANGE_ORDER_STATUS,
  updateOrderStatus, // 更新store订单状态action
  saveTripInfo, //  更新store行程信息action
  savePassengerInfo, // 更新store乘客信息action
  saveTripType, // 更新mtour标示
  MAP_TYPE,
  saveCarState,
  savePointInfo,
  HOT_AREA_TYPE,
  saveReserveTrip
} from '@/store/action/type';
import CoordTransform from '@/utils/CoordTransform';
import { AppFn } from '@/utils/JsBrigde';
const TRIP_POINT_TYPE_UP_STATION = 'TRIP_POINT_TYPE_UP_STATION';
const TRIP_POINT_TYPE_DOWN_STATION = 'TRIP_POINT_TYPE_DOWN_STATION';
const TRIP_POINT_EMPTY = '暂无数据';

const getUpStation= (data) => {
  return data?.filter(station => {
  if(station?.trip_point_type === TRIP_POINT_TYPE_UP_STATION) return station
  });
}
const getDownStation= (data) => {
  return data?.filter(station => {
    if(station?.trip_point_type === TRIP_POINT_TYPE_DOWN_STATION) return station
  });
}
export const getStation = (data) => {
  if(!Array.isArray(data)) return;
  return {
    TRIP_POINT_TYPE_UP_STATION: getUpStation(data)[0]?.name || TRIP_POINT_EMPTY,
    TRIP_POINT_TYPE_DOWN_STATION: getDownStation(data)[0]?.name || TRIP_POINT_EMPTY,
    UP_POINT: getUpStation(data)[0]?.point
  };
};
export const resetLocalData = (dispatch) => {
  dispatch({
    type: CHANGE_ORDER_STATUS,
    data: OrderStatus.LISTEN_ORDER,
  });
  dispatch({
    type: MAP_TYPE.route,
    data: {}
  })
  dispatch(saveTripInfo({}));
}
export const TRIP_TYPE = {
  MTOUR: 'TRIP_TYPE_MTOUR',
  ROBOTAXI: 'TRIP_TYPE_MTAXI',
  CROSSCITY: 'TRIP_TYPE_EXT_MTAXI_CROSS_CITY'
}
export const API_DATA = {
  IDLE: "",
  MTOUR_ACCEPT: 'ACCEPT',
  CAR_START: 'CAR_START',
  WAIT: 'CAR_ARRIVED',
  START: 'START',
  COMPLATE: 'TRIP_OVER',
  CANCEL: 'CANCEL',
  RETRY_DISPATCH: 'RETRY_DISPATCH'
}
export function SaveTripTypeFn(dispatch, trip_type){
  dispatch(saveTripType({
    Mtour: trip_type === TRIP_TYPE.MTOUR
 }));
}
export function SaveTripInfoFn(dispatch, trip_info){
  dispatch(saveTripInfo({
    ...trip_info
  }));
}
export function SaveCarStateFn(dispatch, car_state){
  dispatch(saveCarState({
    car_state
  }));
}
export function savePassengerInfoFn(dispatch, user){
  dispatch(savePassengerInfo(user));
}
export function  SavePointFn(dispatch, point){
  dispatch(savePointInfo({
    point
  }));
}
export function updateOrderStatusFn(dispatch, status, statusExt) {
  switch(status){
    case API_DATA.IDLE:
      dispatch(updateOrderStatus(OrderStatus.LISTEN_ORDER));
      break;
    case API_DATA.MTOUR_ACCEPT:
      dispatch(updateOrderStatus(OrderStatus.MTOUR_ACCEPT));
      break;
    case API_DATA.CAR_START:
      dispatch(updateOrderStatus(OrderStatus.CAR_START));
      break;
    case API_DATA.WAIT:
      dispatch(updateOrderStatus(OrderStatus.WAIT_PASSENGER));
      break;
    case API_DATA.START:
      dispatch(updateOrderStatus(OrderStatus.TRANS_PASSENGER));
      break;
    case API_DATA.COMPLATE:
      dispatch(updateOrderStatus(OrderStatus.ARRIVE_DESTINATION));
      break;
    case API_DATA.RETRY_DISPATCH:
      // console.log('statusExt', statusExt);
      if(statusExt === 'DRIVER_CANCEL'){
        dispatch(updateOrderStatus(OrderStatus.DRIVER_CANCEL));
      }
      if(statusExt === 'DRIVER_OFFLINE'){
        dispatch(updateOrderStatus(OrderStatus.DRIVER_OFFLINE));
      }
      break;
    case API_DATA.CANCEL:
      if(statusExt === 'PASSENGER_CANCEL'){
        dispatch(updateOrderStatus(OrderStatus.PASSENGER_CANCEL));
      }
      if(statusExt === 'PASSENGER_TIMEOUT'){
        dispatch(updateOrderStatus(OrderStatus.PASSENGER_TIMEOUT));
      }
      if(statusExt === 'NO_VALID_CAR'){
        dispatch(updateOrderStatus(OrderStatus.NO_VALID_CAR));
      }
      break;
    default:
      break;
  }
}
export function SaveRouteInfo(dispatch, data){
  dispatch({
    type: MAP_TYPE.route,
    data
  });
}
export function SaveReserveTrip(dispatch, data) {
  dispatch(saveReserveTrip({
    ...data
  })); 
}
export function updateFailRoute(dispatch, data){
  if(Array.isArray(data)) return;
  let org = data.data?.trip_points[0];
  let dst = data.data?.trip_points[1];
  let orgPoint = CoordTransform.wgs84ToGcj02(org?.point?.lon, org?.point?.lat);
  let dstPoint = CoordTransform.wgs84ToGcj02(dst?.point?.lon, dst?.point?.lat);
  dispatch({
    type: MAP_TYPE.route,
    data: {
      start_location:orgPoint.toString(),
      end_location: dstPoint.toString()
    }
  });
  dispatch({
    type: MAP_TYPE.routeResult,
    data: true
  });
}
export function resetStore(dispatch){
  dispatch(saveTripInfo(null));
  dispatch(saveTripType({Mtour: false}));
}
export function getDest(data){
  return CoordTransform.wgs84ToGcj02(getStation(data?.trip_points)?.lon, getStation(data?.trip_points)?.lat);
}
export function getNaviDest(data){
  return CoordTransform.wgs84ToGcj02(data[0]?.point?.lon, data[0]?.point?.lat);
}
export function NoticeNativeFn(type, data){
  switch(type){
    case 'start_navi': 
      let up = getUpStation(data?.trip_points);
      AppFn.NoticeNativeNavInfo(type, {
        destination: getStation(data?.trip_points)?.TRIP_POINT_TYPE_UP_STATION,
        longitude: getNaviDest(up)[0],
        latitude: getNaviDest(up)[1]
      });
      break;
    case 'cancel_order':
      AppFn.NoticeNativeNavInfo(type,{});
      break;
    default:
      break;
  }
}
// export function emitRoboNodeStatus(status){
//   switch(status){
//     case API_DATA.ACCEPT:
//       SendToRoboFn(SendToRoboMap.GET_PASSENGER);
//       break;
//     case API_DATA.WAIT:
//       SendToRoboFn(SendToRoboMap.WAIT_PASSENGER);
//       break
//     case API_DATA.START:
//       SendToRoboFn(SendToRoboMap.TRANS_PASSENGER);
//       break;
//     case API_DATA.COMPLATE:
//       SendToRoboFn(SendToRoboMap.TRIP_OVER);
//     default:
//       break;
//   }
// }
export function HideRouteLoading(dispatch){
  dispatch({
    type: MAP_TYPE.routeLoading,
    data: false,
  });
}
export function orderClearReset(dispatch){
  dispatch({
    type: CHANGE_ORDER_STATUS,
    data: OrderStatus.LISTEN_ORDER,
  });
  // 接单重置robonode路径结果
  dispatch({
    type: MAP_TYPE.routeResult,
    data: false
  });
  // 接单重置robonode路径规划
  dispatch({
    type: MAP_TYPE.route,
    data: {}
  });
  dispatch(saveTripInfo({}));

  dispatch({
    type: HOT_AREA_TYPE.HotAll,
    data: false
  });
  dispatch({
    type: HOT_AREA_TYPE.GoOn,
    data: ''
  });
}