const INIT_STATE = {
    CurTab: 'map'
};

export default function slider(state = INIT_STATE, action) {
    switch (action.type) {
        case 'CHANGE_TAB':
            return { ...state, ...{ CurTab: action.data } };
        default:
            return state;
    }
}