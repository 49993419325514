import React from 'react';
import { HashRouter } from 'react-router-dom';
import { Routes, Route, Navigate } from 'react-router-dom';
import App from '../App';
import Login from '@/pages/Login';
import Home from '@/pages/Home';

export default function Router() {
  return (
    <HashRouter>
      <React.StrictMode>
        <Routes>
          <Route path="home/*" element={<App />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login"/>}></Route>
        </Routes>
      </React.StrictMode>
    </HashRouter>
  );
}
