import { customAlphabet } from 'nanoid/non-secure';
import {HOT_AREA_TYPE } from '@/store/action/type';
import store from '@/store';
import logSend from '@/utils/aLog';
import { fsPush } from "@/utils/request";
/**
 * 去掉空参数
 * @param {Object} obj
 */
 export function removeEmpty(obj) {
    const res = Object.keys(obj).reduce((pre, key) => {
      if (obj[key] || obj[key] === 0) {
        return { ...pre, [key]: obj[key] }
      }
      return pre
    }, {})
    return res
}
/**
 * 获取手机后4位
 * @param {*} phone 
 * @returns 
 */
export function getLastNum(phone) {
  return phone && phone.substr(7)
}
/**
 * 计算行程信息
 * @param {*} map 
 * @returns 
 */
export function caluTripInfo(map){
  return {}
}

/**
 * 获取时间 时:分
 * @param {*} time 
 * @returns 
 */
export function getHourMin(time) {
  if(!(time && time.length) || time === undefined) return ''
  let arr = (time.split(' ')[1]).split(':')
  arr.pop()
  return arr.join(':')
}

/**
 * 获取当前时间 时:分
 * @returns 
 */
export function getCurrentTime(){
  let now = new Date()
  return `${now.getHours()}:${now.getMinutes()}`
}
/**
 * 生成trace_id
 */
export const genTraceId = ()=> {
  const nanoid = customAlphabet('1234567890abcdef', 32)
  return nanoid()
}

/**
 * 获取超时信息
 * @param {*} updateTime 
 * @returns 
 */
export const getTimeOutInfo = (updateTime, nowTime, gap) => {
  if(!updateTime || !nowTime || updateTime == "0001-01-01 00:00:00"){
    return {
      isTimeout: false,
      timeCounter: 0
    }
  }
  let currentTime = (new Date(nowTime)).getTime();
  let startTime = 0; //开始时间
  let isTimeout = false; // 是否超时 
  let timeCounter = 0; // 记录等待时间
  let gapFiveMin = gap * 60; // 超时间隔
  startTime = (new Date(updateTime)).getTime() // 开始时间转换
  let spend = parseInt((currentTime - startTime)/1000); // 等待总时间
  spend >= gapFiveMin ? isTimeout = true : isTimeout = false
  timeCounter = parseInt((currentTime - startTime)/1000)
  return spend <= 0 ? {
    isTimeout,
    timeCounter: 1
  } : {
    isTimeout,
    timeCounter
  }
}
/**
 * 生成路线
 * @param arr station数组
 * @returns routes数组
 */
export const genRoute = (arr) => {
  let newArr = []
  for(let i = 0; i < arr.length; i++){
    for(let j = 0; j < arr.length; j++){
      newArr.push([arr[i], arr[j]])
    }
  }
  return newArr
}

/**
 * 处理NaN数据
 * @param {*} text 
 * @returns 
 */
export const handlerEmpty = (text) => {
  return text + '' === 'NaN' || text === 'NaN:NaN' ? '--' : text;
}

/**
 * 清除系统弹窗
 */
export const removeDialog = () => {
  let nodes = document.querySelectorAll('div[name=dialog]');
  nodes.forEach(item => {
    item.parentNode.removeChild(item);
  });
  store.dispatch({
    type: HOT_AREA_TYPE.HotAll,
    data: false
  });
}

/**
 * Mtour兜底 10分钟，公里数小于1000m, 判定行程是否开始
 */

export const isTripStart = (nowTime, orderTime, distanceRemain) => {
  nowTime = (new Date(nowTime)).getTime();
  orderTime = (new Date(orderTime)).getTime();
  if(orderTime-nowTime < 10 * 60 * 1000 && distanceRemain < 1000){
    return false;
  } else {
    return true;
  }
}

export const getAreaInfo = (areaRef) => {
  const Area = areaRef?.current?.getBoundingClientRect();
  return `${Area?.x}, ${Area?.y}, ${Area?.width}, ${Area?.height}`;
}

export const getArriveTime = (timestamp) => {
  if(timestamp == '--' || !timestamp) return '--';
  let t = new Date(timestamp * 60 * 1000 + new Date().getTime());
  let h = t.getHours();
  let m = t.getMinutes();
  if(h < 10){
    h = `0${h}`;
  }
  if(m < 10){
    m = `0${m}`;
  }
  return `${h}:${m}`;
}

export const isEmptyObj = (object) => {
  if (Object.keys(object).length!= 0) {
    return false;
  }
  return true;
}

export const NotifyAppLocation = (locateInfo) => {
  if(isEmptyObj(locateInfo)) return;
    //fsPush(locateInfo);
    if(window.momentaJsBridge){
      window.momentaJsBridge.dispatch("getLocationData",null, JSON.stringify(locateInfo));
    }
}

export const NotifyAppRecoverRoute = (routeInfo) => {
  if(window.momentaJsBridge){
    window.momentaJsBridge.dispatch("recoverRoute",null, JSON.stringify(routeInfo));
  }
}

// window.logSS = function (data) {
//   fsPush(data);
// }

export const errorConsole = (action, data) => {
  logSend({
    action,
    data
  });
}

export const queryURLParameter = (name) => {
  let url = window.location.href;
  let regx = /([^&?=]+)=([^&?=]+)/g;
  let obj = {};
  url.replace(regx, (...args) => {
    if (obj[args[1]]) {
      obj[args[1]] = Array.isArray(obj[args[1]])
        ? obj[args[1]]
        : [obj[args[1]]];
      obj[args[1]].push(args[2]);
    } else {
      obj[args[1]] = args[2];
    }
  });
  return obj[name]||'';
}

export const pre_time = (s)=> {
  return s?.substring(0, s.length-3);
}