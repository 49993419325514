import React from 'react';
import ReactDOM from "react-dom";
import store from '@/store';
import { HOT_AREA_TYPE } from '@/store/action/type';
import Style from './index.module.less';

class DialogCustom extends React.Component {
  static show = params => {
    let container = document.createElement("div");
    container.style.width = "100%";
    container.setAttribute('name', 'dialog');
    let main = document.getElementById('main');
    main.appendChild(container);
    store.dispatch({
      type: HOT_AREA_TYPE.HotAll,
      data: true
    });
    function closeHandle() {
      ReactDOM.unmountComponentAtNode(container);
      main.removeChild(container);
      container = null;
    }
    ReactDOM.render(
      <DialogCustom {...params} onClose={closeHandle} />,
      container
    );
  };
  constructor(props) {
    super(props);
  }
  handleOk = () => {
    const {onOk} = this.props;
    try {
      onOk();
    } finally {
      this.props.onClose();
      store.dispatch({
        type: HOT_AREA_TYPE.HotAll,
        data: false
      });
    }
  };
  render() {
    const { onCancel, onClose, msg } = this.props;
    return (<div className={Style.Mask}>
      <div className={Style.Container}>
      <div className={Style.Content}>{msg}</div>
      <div className={Style.Btns}>
          <div className={Style.Btn} onClick={()=>{
            onClose();
            onCancel && onCancel();
            store.dispatch({
              type: HOT_AREA_TYPE.HotAll,
              data: false
            });
          }}>取消</div>
          <div className={`${Style.Btn} ${Style.Ok}`} onClick={this.handleOk}>确定</div>
      </div>
    </div>
  </div>)
  }
}

  export default DialogCustom;