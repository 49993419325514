import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Router from './router';
import store from './store';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import VConsole from 'vconsole';
import './utils/robonode';
import './assets/iconfont/iconfont.css'

// new VConsole(0);
Sentry.init({
  dsn: 'https://ff68c90f14534bd2816ae566a7f86abe@sentry.mmtwork.com/11',
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
