import './index.less';
import { memo, useState, useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OrderStatus } from '@/constant/order';
import { CHANGE_ORDER_STATUS } from '@/store/action/type';
import {
  tripStart, // 开始行程
} from '@/utils/api'
import { 
  getLastNum, // 获取手机后4位
  getTimeOutInfo // 获取超时信息
} from '@/utils/index'

// import wait from '@/assets/wait.png';
import DialogCustom from '@/components/common/AlertCommon';
import {
  MTOUR_TIMEOUT,
  ROBOTAXI_TIMEOUT
} from '@/constant/config';
import { MAP_TYPE } from '@/store/action/type';
import { AppFn } from '@/utils/JsBrigde';

const WaitPassenger = ({TRIP_INFO}) => {
  const [timestamp, setTimestamp] = useState(1);
  const [isTimeoutFlag, setTimeFlag] = useState(false);
  const order = useSelector(state => state.order)
  const isMtour = useSelector(state => state.order.Mtour);
  let trip_car_arrived_time = TRIP_INFO?.trip_car_arrived_time;
  let trip_now_time = TRIP_INFO?.trip_now_time;
  const dispatch = useDispatch();
  let trip_id = TRIP_INFO?.trip_id;
  let timerRef = useRef();

  const getTimeGap = (arrive_time, nowTime)=>{
      if(!arrive_time || !nowTime) return {timeCounter: 0}
      let currentTime = (new Date(nowTime)).getTime();
      let startTime = 0; //开始时间
      startTime = (new Date(arrive_time)).getTime() // 开始时间转换
      let timeCounter = parseInt((currentTime - startTime)/1000); // 等待总时间
      return timeCounter <= 0 ? {
        timeCounter: 1
      } : {
        timeCounter
      }
  }

  useEffect(()=>{
    if(trip_car_arrived_time === "0001-01-01 00:00:00") return;
    if(timerRef.current) return;
    if(trip_car_arrived_time && trip_now_time){
      timerRef.current = true
      setTimestamp(1);
      let {timeCounter} = getTimeGap(trip_car_arrived_time, trip_now_time);
      setInterval(()=>{
        setTimestamp(timeCounter);
        timeCounter = timeCounter + 1;
      }, 1000);
    }
  }, [trip_car_arrived_time, trip_now_time])

  useEffect(() => {
    if(isMtour && timestamp >= 30 * 60){
      return setTimeFlag(true);
    }
    if(!isMtour && timestamp >= 5 * 60){
      return setTimeFlag(true);
    }
    setTimeFlag(false);
  }, [timestamp]);

  const [min, sec] = useMemo(() => {
    let min = Math.floor(timestamp / 60);
    let sec = timestamp % 60;
    min = min.toString().padStart(2, '0');
    sec = sec.toString().padStart(2, '0');
    return [min, sec];
  }, [timestamp]);

  function IsGetPassenger(){
    DialogCustom.show({
      msg: '请确认是否接到乘客',
      onOk: () => {
        AppFn.NoticeNativeNavInfo('arrive_pickup_point',{});
        next();
      }
    });
  }

  const next = async () => {
    let carStartRes = await tripStart({
      trip_id
    })
    if(carStartRes.code === 0){
      dispatch({
        type: MAP_TYPE.drivingState,
        data: {},
      });
      // 通知robonode送乘客
      dispatch({
        type: CHANGE_ORDER_STATUS,
        data: OrderStatus.TRANS_PASSENGER,
      });
      // 开始行程 tripStart
      AppFn.playLocalAudio("start.wav");
    }
  };

  return (
    <div className="wait-passenger-container">
        <div className="TopContent">
            <div className="ContentLeft">
                <div className="TopButton" onClick={IsGetPassenger}>
                    接到乘客
                </div>
            </div>
            <div className="ContentRight">
                <div className="phone">
                    <span className="RightLabel">尾号</span>
                    <span className="Number">
                        {getLastNum(order?.PASSENGER_INFO?.ext?.phone)}
                    </span>
                </div>
                <div className="Arravietime">
                    <span className="RightLabel">{isTimeoutFlag ? '乘客已超时' : '已等待'}</span>
                    <span className={isTimeoutFlag ? 'outNumber' : 'Number'}>
                        {
                            isTimeoutFlag
                            ? (
                                isMtour
                                ? (min - MTOUR_TIMEOUT).toString().padStart(2, '0')
                                : (min - ROBOTAXI_TIMEOUT).toString().padStart(2, '0')
                            )
                            : min
                        }:{sec}
                    </span>
                </div>
            </div>
        </div>
    </div>
  );
};

export default memo(WaitPassenger);
