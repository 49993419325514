import { memo } from 'react';
import successIcon from '@/assets/img/orderComplate/icon-success.png';
import { useSelector } from 'react-redux';
import { handlerEmpty } from '@/utils/index'
import GoOnWork from '../goOnWork/index.js'
import './index.less';

function genSumTime(start, end) {
  // console.log('genSumTime', start, end);
  if( !start || !end || start === '0000-00-00 00:00:00' ||  end === '0000-00-00 00:00:00') return '--'
  let gap = 0;
  function getTime(time) {
    return (new Date(time)).getTime()
  }
  start = getTime(start)
  end = getTime(end)
  gap = end - start
  gap = Math.ceil((parseInt(gap/1000)/60));
  return gap <= 1 ? '1' :  gap
}

const OrderComplate = ({TRIP_INFO}) => {
  const isMtour = useSelector(state => state.order.Mtour);
  return (
    <div className="order-complate-container">
      <img src={successIcon} alt="" className="icon" />
      <p className="tip">订单已完成，感谢您的乘坐！</p>
      <div className="trip-info">
        <div className="trip-item">
          <div className="count">
            <span>{ handlerEmpty((TRIP_INFO?.route?.driving_route?.distance/1000).toFixed(1)) || '--'}</span>公里
          </div>
          <div className="trip-name">总里程</div>
        </div>
        <div className="trip-item">
          <div className="count">
            <span>{genSumTime(TRIP_INFO?.trip_start_time, TRIP_INFO?.trip_end_time)}</span>分钟
          </div>
          <div className="trip-name">总时长</div>
        </div>
      </div>
      { 
       isMtour ? <div className="address-box">
        <div className='station up'>
          <label>路线</label>
          <p>{TRIP_INFO?.route?.name || 'Mtour路线'}</p>
        </div>
        <div className='station'>
          <label>上下车站点</label>
          <p>{TRIP_INFO?.TRIP_POINT_TYPE_UP_STATION}-{TRIP_INFO?.TRIP_POINT_TYPE_DOWN_STATION}</p>
        </div>
      </div> :
      <div className="address-box">
        <div className="address-content start">
          {TRIP_INFO?.TRIP_POINT_TYPE_UP_STATION}
          </div>
        <div className="address-content end">
          {TRIP_INFO?.TRIP_POINT_TYPE_DOWN_STATION}
        </div>
      </div>
      }
      <div className="btn-box">
        {/* <div onClick={rest} className="btn border disabled" style={{ width: '1.47rem' }}>
          休息
        </div> */}
        <div className="btn" style={{ width: '100%' }}>
          <GoOnWork TRIP_INFO={TRIP_INFO}></GoOnWork>
        </div>
      </div>
    </div>
  );
};

export default memo(OrderComplate);
