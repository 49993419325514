
import DialogCustom from '@/components/common/AlertCommon';
import { carStart } from '@/utils/api';
import "./index.less";

export default function MtourAccept(TRIP_INFO) {
    function IsGet(){
        DialogCustom.show({
            msg: '请确认是否去接乘客',
            onOk: () => {
                getMtourPassenger()
            }
        });
    }
    const getMtourPassenger = async () => {
        await carStart({
            trip_id: TRIP_INFO?.TRIP_INFO?.trip_id
        });
    }
    return (<div className="mtour-accept-wrapper">
        <div className="get-btn" onClick={IsGet}>去接乘客</div>
        <div className="get-tip">请提前<span style={{color: "rgba(0, 104, 235, 1)"}}>60</span>分钟出发</div>
    </div>)
}