import './index.less';
import { memo } from 'react';

const RouteLoading = () => {
  return (
    <div className="route-loading">
      <span className="spinner"></span>
      智驾规划中...
    </div>
  );
};

export default memo(RouteLoading);
