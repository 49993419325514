import { memo, useState, useMemo, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './index.less';
import { cancelOrder } from '@/utils/api';
import { OrderStatus } from '@/constant/order';
import { updateOrderStatus, HOT_AREA_TYPE } from '@/store/action/type';
import { getTimeOutInfo, pre_time } from '@/utils/index';
import DialogCustom from '@/components/common/AlertCommon';
import {
  MTOUR_TIMEOUT,
  ROBOTAXI_TIMEOUT
} from '@/constant/config';
import { AppFn } from '@/utils/JsBrigde';
import mtour_icon from "./mtour_icon.png";
const TRIP_TYPE_MTOUR = "TRIP_TYPE_MTOUR" // 跨城打车

const OrderCard = ({ children, showCancel = true }) => {
  const order = useSelector((state) => state.order);
  const order_status = useSelector((state) => state.order.OrderStatus);
  const isMtour = useSelector(state => state.order.Mtour);
  let trip_car_arrived_time = order.TRIP_INFO?.trip_car_arrived_time;
  const trip_now_time = order.TRIP_INFO?.trip_now_time;
  const car_time = order.TRIP_INFO?.car_time;
  const trip_type_ext = order.TRIP_INFO?.trip_type_ext;
  const [cancelFlag, setCancelFlag] = useState(false);
  const curTab =  useSelector((state) => state.slider.CurTab);

  const hotCard = useRef(null);
  const flagTimer = useRef(null);
  const dispatch = useDispatch();

  const bottom = useMemo(() => {
    return cancelFlag ? 0 : (!showCancel ? (isMtour ? "-1rem" : '-0.86rem') : (isMtour ? '-1.5rem' : '-1.2rem'));
  }, [showCancel, cancelFlag]);

  const handleCancelOrder = async () => {
    let { isTimeout } = getTimeOutInfo(trip_car_arrived_time, trip_now_time, isMtour? MTOUR_TIMEOUT : ROBOTAXI_TIMEOUT);
    let res = await cancelOrder({
      trip_id: order.TRIP_INFO.trip_id,
      state: isTimeout ? 'TRIP_CANCEL_STATE_PASSENGER_TIMEOUT' : 'TRIP_CANCEL_STATE_DRIVER_CANCEL',
    });
    if (res.code === 0) {
      try {
        // await SendToRoboFn(SendToRoboMap.LISTEN_ORDER);
      } finally {
        isTimeout ? dispatch(updateOrderStatus(OrderStatus.PASSENGER_TIMEOUT)) : dispatch(updateOrderStatus(OrderStatus.DRIVER_CANCEL));
        AppFn.NoticeNativeNavInfo('cancel_order',{});
      }
    }
  };
  const IsCancel = () => {
    DialogCustom.show({
      msg: '确认要取消订单吗？',
      onOk: () => {
        handleCancelOrder()
      }
    });
  }
  // 关闭H5弹窗
  useEffect(()=>{
    window.closeH5Dialog = function(){
      setCancelFlag(false);
    }
  }, []);

  useEffect(()=>{
    const CardArea = hotCard.current.getBoundingClientRect();
    const CardAreaInfo = `${CardArea.x}, ${CardArea.y}, ${CardArea.width}, ${CardArea.height}`;
    dispatch({
      type: HOT_AREA_TYPE.Card,
      data: CardAreaInfo
    });
  })

  useEffect(()=>{
    // curTab
    console.log("curTab", curTab);
    if(curTab!== "map"){
      clearTimeout(flagTimer.current);
      setCancelFlag(false);
    }
  }, [curTab]);

  useEffect(() => {
      if (order_status === OrderStatus.CAR_START) {
          setCancelFlag(true);
          flagTimer.current = setTimeout(() => {
              setCancelFlag(false);
          }, 10000);
      }
      return (() => {
          flagTimer.current = null;
      })
  }, [order_status])

  return (
    <div ref={hotCard} className='order-card' style={{ bottom: bottom }}>
      {
        isMtour ? <span className='cross-label'>
          <img src={mtour_icon} alt="mtour标识" />
        </span> : ''
      }
      <div className="click-area" onClick={() => {
      try{
        if(!cancelFlag){
          AppFn.CloseNativeNavSetting();
        }
      } finally{
        setCancelFlag(!cancelFlag);
      }
      flagTimer.current = null;
      }}>
        <span className={`arrow ${!cancelFlag ? 'up': ''}`} ></span>
      </div>
      {children}
      <div className="hide-box">
          <div className="hide-content">
              <div className="hide-left"></div>
              { isMtour ? <div className="hide-right">
                  <div className="upstation" style={{minHeight: "0.18rem"}}>
                    {
                      trip_type_ext === "TRIP_TYPE_EXT_BASIC" ? "": <>
                        <span className="right-label">用车时间：</span>
                        <span className="right-name">
                          {pre_time(car_time)}
                        </span>
                      </>
                    }
                  </div>
                  <div className="phone" style={{"marginBottom": "0.03rem", "lineHeight": "0.18rem"}}>
                      <span className="right-label">路线：</span>
                      <span className="right-name">
                        {order?.TRIP_INFO?.route?.name}
                      </span>
                  </div>
                  <div className="downstation">
                      <span className="right-label">上下车点：</span>
                      <span className="right-name">
                      {order?.TRIP_INFO?.TRIP_POINT_TYPE_UP_STATION} - {order?.TRIP_INFO?.TRIP_POINT_TYPE_DOWN_STATION}
                      </span>
                  </div>
                  <div className="phone" style={{"lineHeight": "0.18rem"}}>
                      <span className="right-label">乘客电话：</span>
                      <span className="right-name">
                        {order?.PASSENGER_INFO?.ext?.phone}
                      </span>
                  </div>
                  
              </div> : <div className="hide-right">
                  <div className="upstation">
                      <span className="right-label">上车站点：</span>
                      <span className="right-name">
                          {order?.TRIP_INFO?.TRIP_POINT_TYPE_UP_STATION}
                      </span>
                  </div>
                  <div className="downstation">
                      <span className="right-label">下车站点：</span>
                      <span className="right-name">
                          {order?.TRIP_INFO?.TRIP_POINT_TYPE_DOWN_STATION}
                      </span>
                  </div>
                  <div className="phone" style={{"lineHeight": "0.18rem"}}>
                      <span className="right-label">乘客电话：</span>
                      <span className="right-name">
                          {order?.PASSENGER_INFO?.ext?.phone}
                      </span>
                    </div>
              </div>
              }
          </div>
          {(
              order.OrderStatus !== OrderStatus.TRANS_PASSENGER || 
              order.OrderStatus !== OrderStatus.ARRIVE_DESTINATION) && showCancel && 
              <div className="cancel-btn-bottom" onClick={IsCancel}>
              取消订单
              </div>
          }
      </div>
    </div>
  );
};

export default memo(OrderCard);
