import { get, post } from '@/utils/request';
import { API } from '@/constant/config';

// 获取车辆列表
// TODO: 临时使用
export const getCarList = (params) => get(API.CarList, params);
// 登录
export const login = (params) => post(API.BindCar, params);
// 退出
export const logout = (params) => post(API.UnBindCar, params);
// 轮询订单接口
export const pollOrder = (params) => get(API.CarFlow, params);
// 司机接单
export const AcceptOrder = (params) => get(API.CarAccept, params);
// 司机取消订单
export const cancelOrder = (params) => post(API.CarCancel, params);
// 到达上车点
export const carArrived = (params) => post(API.CarArrived, params);
// 轮询同步行程事件消息
export const eventInfoSync = (params) => post(API.InfoSync, params);
// 同步实际送驾行驶路线
export const drivingRouteSync = (params) => post(API.RouteSync, params);
// 行程开始
export const tripStart = (params)=> post(API.TripStart, params);
// 行程结束
export const tripEnd = (params) => post(API.TripEnd, params);
// 继续接单
export const continueWork = (params)=> post(API.ContinueWork, params);
// 获取接驾路线
export const pickupRoute = (params)=> post(API.PickupRoute, params);
// 获取用户信息
export const driverInfo = (params) => get(API.DriverInfo, params);
// 上班
export const onDuty = (params) => post(API.onDuty, params);
// 下班
export const offDuty = (params) => post(API.offDuty, params);
// 订单列表
export const orderList = (params) => get(API.orderList, params);
// Mtour接乘客
export const carStart = (params) => post(API.carStart, params);
// 获取车牌 getCarNum
export const getCarNum = (params) => get(API.getCarNum, params);